<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>API Environments:</h2>
      </v-col>
    </v-row>
    <v-row id="v-step-2">
      <v-col cols="12"  md="12" v-if="!deployments.environments">
        <v-row align="center">
          <v-skeleton-loader
            class="ma-2 pa-1"
            max-width="350"
            min-width="350"
            max-height="142"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="ma-2 pa-1"
            max-width="350"
            min-width="350"
            max-height="142"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="ma-2 pa-1"
            max-width="350"
            min-width="350"
            max-height="142"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="ma-2 pa-1"
            max-width="350"
            min-width="350"
            max-height="142"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="ma-2 pa-1"
            max-width="350"
            min-width="350"
            max-height="142"
            type="card"
          ></v-skeleton-loader>
        </v-row>
      </v-col>
      <v-col cols="12" md="12" v-else>
        <v-row align="center">
            <v-card
              class="ma-2 pa-1"
              max-width="350"
              min-width="350"
              outlined
              v-for="(deployment, index) in deployments.environments" :key="index"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{ deployment.environment.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="deployment.environment.isInternetAccessible">Internet Facing - Deploy to request approval</v-list-item-subtitle>
                  <v-list-item-subtitle v-else></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar id="v-step-4">
                  <template v-if="currentlyDeploying.includes(deployment.environment.id)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-progress-circular
                          indeterminate
                          rotate
                          :size=25
                          :width=3
                          color="light-blue"
                        ></v-progress-circular>
                        </v-btn>
                      </template>
                      <span>Working, Please wait</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                  <v-tooltip bottom v-if="environmentIncludesRevisionInPending(deployment)">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="cancelRequestConfirm(deployment.environment.id)">
                        <v-icon color="gray">mdi-timer-sand-empty</v-icon>
                      </v-btn>
                    </template>
                    <span>The curent revision you are viewing is pending approval</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="environmentIncludesRevision(deployment)">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon color="green">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>The curent revision you are viewing has been deployed to this environment</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="environmentsIncludeVersion(deployment)">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon color="orange">mdi-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>A different revision that what you are viewing has been deployed to this environment</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="deployApi(deployment.environment.id, deployment.environment.isInternetAccessible)">
                        <v-icon>mdi-circle-off-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>This API version has not yet been deployed to this environment.</span>
                  </v-tooltip>
                </template>
                </v-list-item-avatar>
              </v-list-item>
              <v-card-actions id="v-step-3">
                <v-btn v-if="!environmentIncludesRevision(deployment) && !environmentIncludesRevisionInPending(deployment)" small color="primary" @click="deployApi(deployment.environment.id, deployment.environment.isInternetAccessible)">Deploy</v-btn>
                <v-btn v-if="environmentIncludesRevisionInPending(deployment)" small color="warning" @click="cancelRequestConfirm(deployment.environment.id)">Cancel Request</v-btn>
                <v-btn v-if="environmentIncludesRevision(deployment) && !environmentIncludesRevisionInPending(deployment)" color="error" small @click="unDeployConfirm(deployment.environment.id)">Undeploy</v-btn>
            </v-card-actions>
            </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'deployments-component',
  props: {
    deployments: {
      required: true
    },
    apiMetaData: {
      required: true
    },
    apiId: {
      required: true
    },
    api: {
      required: true
    }
  },
  data: () => ({
    currentlyDeploying: [ ]
  }),
  methods: {
    deployApi: function (environmentId, isInternetFacing) {
      if (isInternetFacing) {
        this.$store.commit('showDialog', {
          message: `<h3>You are about to request approval for this API to be deployed to the internet.</h3><br/>External APIs require ISC approval prior to being published. <br/>Please read more information about this process <a href="https://confluence.app.medcity.net/c/display/EAM1/Exposing+APIs+on+the+Internet">here</a>. <br/>Click Continue to request deployment approval. <br/><br/><br/>Warning. Deploying this API will overwrite any other revisions for version ${this.api.openApiDocument.info.version}. Other versions that are already deployed will not be overwritten. Are you sure you wish to continue?`,
          confirmMethod: () => {
            this.deployApiWithConsent(environmentId)
          },
          cancelMethod: () => {
            return false
          }
        })
      } else {
        this.$store.commit('showDialog', {
          message: `Warning. Deploying this API will overwrite any other revisions for version ${this.api.openApiDocument.info.version}. Other versions that are already deployed will not be overwritten. Are you sure you wish to continue?`,
          confirmMethod: () => {
            this.deployApiWithConsent(environmentId)
          },
          cancelMethod: () => {
            return false
          }
        })
      }
    },
    deployApiWithConsent: function (environmentId) {
      this.currentlyDeploying.push(environmentId)
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/environments/${environmentId}/apis/${this.apiMetaData.apiId}/revisions/${this.api.revision}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$emit('deployEvent')
            setTimeout(() => {
              this.currentlyDeploying = this.currentlyDeploying.filter(x => {
                return x !== environmentId
              })
              this.$store.commit('triggerSnack', { snackMessage: 'The api has been deployed successfully', snackColor: 'success' })
            }, 1000)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deployApiWithConsent(environmentId) })
          })
    },
    unDeployConfirm: function (environmentId) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to undeploy this API from this environment?',
        confirmMethod: () => {
          this.unDeploy(environmentId)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    unDeploy: function (environmentId) {
      this.currentlyDeploying.push(environmentId)
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/environments/${environmentId}/apis/${this.apiMetaData.apiId}/revisions/${this.api.revision}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$emit('deployEvent')
            setTimeout(() => {
              this.currentlyDeploying = this.currentlyDeploying.filter(x => {
                return x !== environmentId
              })
              this.$store.commit('triggerSnack', { snackMessage: 'The api has been undeployed successfully', snackColor: 'success' })
            }, 1000)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.unDeploy(environmentId) })
          })
    },
    environmentsIncludeVersion: function (deployment) {
      if (deployment.currentDeployments.filter(x => x.version === this.api.openApiDocument.info.version).length > 0) {
        return true
      }
      return false
    },
    environmentIncludesRevision: function (deployment) {
      if (deployment.currentDeployments.filter(x => x.revision === this.api.revision && (x.status === 'Approved' || x.status === 0)).length > 0) {
        return true
      }
      return false
    },
    environmentIncludesRevisionInPending: function (deployment) {
      if (deployment.currentDeployments.filter(x => x.revision === this.api.revision && (x.status === 'Pending' || x.status === 1)).length > 0) {
        return true
      }
      return false
    },
    cancelRequestConfirm: function (environmentId) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to cancel your approval request? This cannot be undone.',
        confirmMethod: () => {
          this.cancelRequest(environmentId)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    cancelRequest: function (environmentId) {
      this.currentlyDeploying.push(environmentId)
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/environments/${environmentId}/apis/${this.apiMetaData.apiId}/revisions/${this.api.revision}/cancel`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$emit('deployEvent')
            setTimeout(() => {
              this.currentlyDeploying = this.currentlyDeploying.filter(x => {
                return x !== environmentId
              })
              this.$store.commit('triggerSnack', { snackMessage: 'The approval request has been successfully cancelled', snackColor: 'success' })
            }, 1000)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.cancelRequest(environmentId) })
          })
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.$emit('deploymentsRendered')
    })
  }
}
</script>

<style scoped>

</style>
