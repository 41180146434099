 <template>
  <v-container>
    <div v-if="swaggerEditorEnabled" class="swagger-editor-container">
      <v-row style="margin-top: -25px; margin-bottom: -10px">
          <v-col cols="12" md="12" class="d-flex justify-center">
            <v-btn small @click="closeSwaggerEditor">Save and close editor</v-btn>
          </v-col>
      </v-row>
      <v-row style="margin-top: -20px; margin-bottom: -38px">
          <v-col cols="12" md="12" class="d-flex justify-center">
            <p>This swagger may contain secrets, please do not share with others</p>
          </v-col>
      </v-row>
      <div id="swagger-editor"></div>
    </div>
    <div v-else>
      <v-row justify="center">
        <v-col cols="12" md="12" id="v-step-0">
            <h1 class="text-center">Build a New Api</h1>
              <p class="text-center">Follow the steps below to create a new api</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-form ref="form">
            <v-stepper v-model="step" :disabled="isSubmitted" id="v-step-1">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1" editable>Build Options</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2" :rules="[step2Validated]" editable>API Settings</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step id="v-step-13" :complete="step > 3" step="3" :rules="[step3Validated]" editable>API Variables</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step id="v-step-17" :complete="step > 4" step="4" :rules="[step4Validated]" editable>API Methods</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 5" step="5" editable>API Policies</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 6" step="6" editable :rules="[step6Validated]">Target Endpoint Settings</v-stepper-step>
                <v-divider></v-divider>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card class="d-md-flex mb-3"
                              outlined
                              @click="selectSwaggerOption('existing')"
                              v-bind:class="{ selected: selectedSwagger == 'existing' }">
                        <v-card-text id="v-step-2">
                          Build an API using an existing swagger document
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card class="d-md-flex mb-3"
                          outlined
                          @click="selectSwaggerOption('new')"
                          v-bind:class="{ selected: selectedSwagger == 'new' }"
                          :loading="isSubmitted">
                        <v-card-text>
                          Build an API without a swagger/openapi document
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <template v-if="selectedSwagger == 'existing'">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="api.originalSwaggerUrl"
                              label="OpenApi Url"
                              hint="URL to your JSON Swagger Doc"
                              id="v-step-3"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-select :items="swaggerAuthTypes" v-model="api.swaggerAuth.type" label="Swagger Auth" />
                          </v-col>
                        </v-row>
                        <v-row v-if="api.swaggerAuth.type == 'Basic Auth'">
                          <v-col cols="12" md="6">
                            <v-text-field v-model="api.swaggerAuth.username" label="Swagger Auth Username" />
                          </v-col>
                        </v-row>
                        <v-row v-if="api.swaggerAuth.type == 'Basic Auth'">
                          <v-col cols="12" md="6">
                            <v-text-field type="password" v-model="api.swaggerAuth.password" label="Swagger Auth Password" />
                          </v-col>
                        </v-row>
                        <v-row v-if="api.swaggerAuth.type == 'Bearer Token'">
                          <v-col cols="12" md="6">
                            <v-text-field type="password" v-model="api.swaggerAuth.bearerToken" label="Swagger Auth Bearer Token" />
                          </v-col>
                        </v-row>
                      </v-container>
                      <div id="v-step-4">
                        <v-btn color="primary" @click="processSwagger">Continue</v-btn>
                      </div>
                    </template>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="6">
                          <v-text-field
                            label="Name"
                            id="v-step-5"
                            :rules="requiredField"
                            v-model="api.openApiDocument.info.title"
                            :disabled="isSubmitted"
                            hint="The display name subscribers will see for this API"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-textarea
                          v-model="api.openApiDocument.info.description"
                          label="Description"
                          id="v-step-6"
                          :rules="requiredField"
                          :disabled="isSubmitted"
                          hint="The description of this api"
                        ></v-textarea>
                        <v-btn x-small outlined @click="previewMarkdownDialog = true">Preview Markdown</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-combobox
                          v-model="api.tags"
                          hint="Type a tag and press enter. Maximum of 5."
                          :rules="tagRules"
                          label="Tags"
                          multiple
                          persistent-hint
                          small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                          <v-text-field
                            label="Path"
                            v-model="apiPath"
                            id="v-step-7"
                            :rules="requiredField"
                            :disabled="isSubmitted"
                            hint="The path after the gateway URL for this API. You can type {version} to move the version around in your api. e.g. path/{version}/testing"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="1">
                          <v-text-field
                            label="Version"
                            :rules="requiredField"
                            id="v-step-8"
                            v-model="api.openApiDocument.info.version"
                            class="md-1"
                            :disabled="isSubmitted"
                            hint="ex. 1.0.0"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="7">
                        <v-text-field filled id="v-step-9" label="Full Path" v-model="fullPath" disabled></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                          <v-switch
                            v-model="api.isSubscribable"
                            id="v-step-10"
                            class="ma-4"
                            label="Subscribable"
                            :disabled="isSubmitted"
                            persistent-hint
                            hint="If enabled, this API will be available to all HCA developers"
                          ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                          <v-switch
                            v-model="api.useRestrictPaths"
                            id="v-step-10"
                            class="ma-4"
                            label="Restrict API calls to only matching verbs and routes"
                            :disabled="isSubmitted"
                            hint="If enabled, API callers will only be able to call verbs and routes you specify in step 4"
                            persistent-hint
                          ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" id="v-step-11">
                        <v-select
                          v-model="api.environmentsRequiringApproval"
                          :items="environments"
                          item-text="displayName"
                          item-value="id"
                          label="Environments where manual approval is required"
                          multiple
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" id="v-step-26">
                        <v-select required @click:clear="proxySecurityPolicy = null" clearable :error-messages="proxySecurityPolicyError ? 'At least one proxy security policy must be provided' : null" label="Proxy Security Policy" :items="proxySecurityPolicies" item-text="displayName" return-object v-model="proxySecurityPolicy" hint="The front-end security policy the gateway will use to secure the API call"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <policy-item-component v-if="proxySecurityPolicy" v-model="proxySecurityPolicy"/>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn id="v-step-12" color="primary" @click="step = 3">Continue</v-btn>
                  <v-btn dark color="purple" class="ml-2" @click="openSwaggerEditor">Edit Swagger Doc</v-btn>
                </v-stepper-content>

                <v-stepper-content step="3" :rules="[() => false]">
                  <v-container fluid>
                    <v-row>
                      <v-btn @click="addVariable" id="v-step-14">Add Variables</v-btn>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                      <v-simple-table id="v-step-15">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th style="width: 20%" class="text-left">Key</th>
                              <th style="width: 50%" class="text-left">Value</th>
                              <th style="width: 20%" class="text-left">Environment</th>
                              <th style="width: 10%" class="text-left">Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in api.variables" :key="index">
                              <td><v-text-field label="Key" v-model="item.key" hint="The key you can reference for this variable"/></td>
                              <td><v-text-field @click:append="toggleSecret(index)" :type="api.variables[index].isSecret ? 'password' : 'text'" :append-icon="api.variables[index].isSecret ? 'mdi-eye' : 'mdi-eye-off'" label="Value" v-model="item.value" hint="The value that will replace the key when this API is called" /></td>
                              <td><v-select :items="environments" v-model="item.environment" item-text="displayName" item-value="name" hint="The environment where this variable should be scoped"/></td>
                              <td><v-btn icon @click="deleteVariable(index)"><v-icon>mdi-delete</v-icon></v-btn></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <div v-if="!step3Validated" class="error--text">Make sure you don't have duplicate variables in the same environment and that each variable has a key and environment.</div>
                    </v-row>
                  </v-container>
                  <div id="v-step-16">
                    <v-btn color="primary" @click="step = 4">Continue</v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <v-container fluid>
                    <v-row id="v-step-18">
                        <v-col cols="12" md="12" class="mb-2" v-if="step4Error">
                        <span class="error--text">{{step4Error}}</span>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-select label="Verb" multiple :disabled="isSubmitted" v-model="newRoute.verbs" :items="verbs">
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-text-field
                          v-model="newRoutePath"
                          :prefix="fullPath"
                          :disabled="isSubmitted"
                          hint="The URL path for this route"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-text-field
                          label="Name"
                          v-model="newRoute.name"
                          :disabled="isSubmitted"
                          hint="A display name in swagger for this route. By default this name will be prepended by the verb name (e.g. GET, POST)."
                          ></v-text-field>
                        </v-col>
                      <v-col cols="12" md="2" class="mb-2">
                        <v-btn large :disabled="isSubmitted || newRoute.verbs.length === 0 || !newRoutePath || !newRoute.name" @click="addRoute">Add route</v-btn>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="methodHeaders"
                          :items="routes"
                          item-key="key"
                          sort-by="path"
                        >
                        <template v-slot:item.edit="{ item }">
                            <v-btn icon @click="editRoute(item)" id="v-step-19"><v-icon>mdi-pencil</v-icon></v-btn>
                          </template>
                          <template v-slot:item.options="{ item }">
                            <v-btn icon @click="deleteRoutePrompt(item)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn color="primary" id="v-step-24" @click="step = 5">Continue</v-btn>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <v-container>
                    <v-row>
                      <v-col>
                        <h3>Add additional policies to your API:</h3>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="currentAdditionalPolicySelectionId"
                          :items="genericPolicies"
                          label="Policy"
                          required
                          item-text="displayName"
                          item-value="id"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-btn outlined large color="info" style="margin-top: 5px" :disabled="!currentAdditionalPolicySelectionId" @click="addAdditionalPolicy">Configure and Add Policy</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Policy Name</th>
                                <th class="text-left">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in additionalPolicies" :key="index">
                                <td>{{ item.displayName }}</td>
                                <td>
                                  <v-btn icon @click="editAdditionalPolicy(index)">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                  <v-btn icon @click="deleteAdditionalPolocy(index)">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn color="primary" @click="step = 6">Continue</v-btn>
                </v-stepper-content>

                <v-stepper-content step="6">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                  id="v-step-25"
                                  label="Target Server Url"
                                  :rules="requiredField"
                                  v-model="api.url"
                                  :disabled="isSubmitted"
                                  hint="This should be the full URL to your backend server where you are hosting your API"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch v-model="api.useProxy" label="Use HCA Proxy Server" hint="This should be selected if the API you're calling is on a server outside the HCA network" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" id="v-step-26">
                            <v-select @click:clear="securityPolicy = null" clearable :error-messages="securityPolicyError ? 'At least one security policy must be provided' : null" label="Endpoint Security Policy" :items="endpointSecurityPolicies" item-text="displayName" return-object v-model="securityPolicy" hint="The security policy you're using to secure the backend server where you're hosting your API"/>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <policy-item-component v-if="securityPolicy" v-model="securityPolicy"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-col cols="12" md="12">
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Advanced Settings
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                 <p>For details about API timeouts <a target="_blank" href="https://confluence.app.medcity.net/c/display/EAM1/Step+6%3A+Target+Endpoints+Settings">view our documentation here</a></p>
                                <v-select
                                  v-model="apiTimeout"
                                  item-text="text"
                                  item-value="value"
                                  hint="Adjusting this timeout will cause Apigee to wait either 1 minute or 3 minutes for your API to respond with a result"
                                  :items="apiTimeoutItems"
                                  :menu-props="{ top: true, offsetY: true }"
                                  label="API Call Timeout"
                                  persistent-hint
                                ></v-select>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn color="primary" id="v-step-27" @click="submit" :disabled="isSubmitted" :loading="isSubmitted">Submit</v-btn>
                  <p id="error-message" v-if="!validated">There are one or more issues with your api. Please check each section and correct any isues</p>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="showEditRouteModal"
      width="1000"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row id="v-step-20">
              <v-col cols="12" md="12">
                <p><v-select label="Verb" :disabled="isSubmitted" :rules="requiredField" v-model="currentMethodItem.newVerb" :items="verbs" /></p>
                <p><v-text-field label="Name" :disabled="isSubmitted" :rules="requiredField" v-model="currentMethodItem.name" /></p>
                <p><v-text-field id="v-step-21" v-on:keyup="definePathProperties($event)" label="Path" :rules="requiredField" v-model="currentMethodItem.newPath" :disabled="isSubmitted" /></p>
                <p>Method Type:</p>
                <v-btn-toggle
                  v-model="currentMethodItem.type"
                  tile
                  group
                  @change="checkMethodType"
                >
                  <v-btn value="standard">
                    Standard
                  </v-btn>
                  <v-btn value="prototype">
                    Prototype
                  </v-btn>
                  <v-btn value="override">
                    Target URL Override
                  </v-btn>
                </v-btn-toggle>
                <div v-if="currentMethodItem.type === 'standard'" style="border: 1px solid black; padding: 5px; margin-top:5px;">
                  <h3>Standard Settings</h3>
                  <p>This method will follow the standard process flow</p>
                </div>
                <div v-if="currentMethodItem.type === 'prototype'" style="border: 1px solid black; padding: 5px;">
                  <h3>Prototype Settings:</h3>
                  <p><v-select id="v-step-21b" label="Prototype Content Type" :items="prototypeContentTypeItems" :disabled="isSubmitted" v-model="currentMethodItem.prototypeContentType" /></p>
                  <p><v-textarea id="v-step-21c" label="Prototype Response Body" v-model="currentMethodItem.prototypeBody" :disabled="isSubmitted" /></p>
                </div>
                <div v-if="currentMethodItem.type === 'override'" style="border: 1px solid black; padding: 5px;">
                  <h3>Target Url Settings:</h3>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                      :rules="requiredField"
                        label="Target Server Url"
                        v-model="currentMethodItem.targetUrl"
                        :disabled="isSubmitted"
                        hint="This should be the full URL to your backend server where you are hosting your API"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch v-model="currentMethodItem.useProxy" label="Use HCA Proxy Server" hint="This should be selected if the API you're calling is on a server outside the HCA network" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-select clearable label="Endpoint Security Policy" :items="endpointSecurityPolicies" item-text="displayName" return-object v-model="currentMethodItem.overrideSecurityPolicy" hint="The security policy you're using to secure the backend server where you're hosting your API" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <policy-item-component v-if="currentMethodItem.overrideSecurityPolicy" v-model="currentMethodItem.overrideSecurityPolicy" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Advanced Settings
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <p>Here are instructions. <a href="">documentation on api timeouts</a></p>
                            <v-select
                              v-model="apiTimeoutCurrentMethod"
                              item-text="text"
                              item-value="value"
                              hint="Adjusting this timeout will cause Apigee to wait either 1 minute or 3 minutes for your API to respond with a result"
                              :items="apiTimeoutItems"
                              :menu-props="{ top: true, offsetY: true }"
                              label="API Call Timeout"
                              persistent-hint
                            ></v-select>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row id="v-step-22">
              <v-col cols="12" md="6">
                <p>Path Parameters:</p>
                <p><v-btn @click="addParameter('path')">+ Parameter</v-btn></p>
                <p>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Parameter</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Description</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(parameter, index) in currentMethodItem.root[currentMethodItem.verb.toLowerCase()].parameters" :key="index">
                          <template v-if="parameter.in === 'path'">
                            <td><v-text-field v-model="parameter.name" /></td>
                            <td><v-select :items="parameterTypes" v-model="parameter.type" /></td>
                            <td><v-text-field v-model="parameter.description" /></td>
                            <td><v-btn icon @click="removeParameterItem(parameter, 'path')"><v-icon>mdi-delete</v-icon></v-btn></td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p>Query Parameters:</p>
                <p><v-btn @click="addParameter('query')">+ Parameter</v-btn></p>
                <p>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Parameter</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Description</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(parameter, index) in currentMethodItem.root[currentMethodItem.verb.toLowerCase()].parameters" :key="index">
                          <template v-if="parameter.in === 'query'">
                            <td><v-text-field v-model="parameter.name" /></td>
                            <td><v-select :items="parameterTypes" v-model="parameter.type" /></td>
                            <td><v-text-field v-model="parameter.description" /></td>
                            <td><v-btn icon @click="removeParameterItem(parameter, 'query')"><v-icon>mdi-delete</v-icon></v-btn></td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="v-step-23"
            color="primary"
            text
            :disabled="!currentMethodItem.newVerb || !currentMethodItem.name || !currentMethodItem.newPath"
            @click="saveRoute"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAdditionalPolicyDialog"
      width="1000"
    >
      <v-card>
        <policy-item-component v-model="currentAdditionalPolicy" :flat="true" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAdditionalPolicyEdit">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            @click="saveAdditionalPolicy"
          >
            Add Policy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="previewMarkdownDialog"
      width="500"
      >
        <v-card>
          <v-card-title
            :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
            primary-title
          >
            Markdown Preview
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <div v-html="compiledMarkdown(api.openApiDocument.info.description)"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="previewMarkdownDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import jsyaml from 'js-yaml'
import SwaggerEditor, { plugins } from 'swagger-editor'
import SwaggerUI from 'swagger-ui'
import 'swagger-editor/dist/swagger-editor.css'
import marked from 'marked'
import policyItemComponent from '@/components/policyItemComponent'

export default {
  name: 'ApiBuilder',
  components: {
    'policy-item-component': policyItemComponent
  },
  computed: {
    newRoutePath: {
      get: function () {
        return this.newRoute.path
      },
      set: function (value) {
        if (!value.startsWith('/')) {
          this.newRoute.path = `/${value}`
        } else {
          this.newRoute.path = value
        }
      }
    },
    apiPath: {
      get: function () {
        return this.api.path
      },
      set: function (value) {
        value = value.replace(' ', '-')
        this.api.path = value
      }
    },
    fullPath: {
      get: function () {
        if (this.api.path && this.api.path.includes('{version}')) {
          return this.product.baseUrlPath + this.api.path.replace('{version}', this.api.openApiDocument.info.version)
        }
        return `${this.product.baseUrlPath}${this.apiPath.replace(/\/*$/, '')}/${this.api.openApiDocument.info.version}`
      }
    },
    apiTimeout: {
      get: function () {
        return this.api.timeout ?? 1
      },
      set: function (value) {
        this.api.timeout = value
      }
    },
    apiTimeoutCurrentMethod: {
      get: function () {
        return this.currentMethodItem.timeout ?? 1
      },
      set: function (value) {
        this.currentMethodItem.timeout = value
      }
    },
    genericPolicies: {
      get: function () {
        if (this.policies) {
          return this.policies.filter(x => {
            return (x.policyType === 2 || x.policyType === 'Generic')
          })
        }
        return [ ]
      }
    },
    endpointSecurityPolicies: {
      get: function () {
        if (this.policies) {
          return this.policies.filter(x => {
            return (x.policyType === 0 || x.policyType === 'EndpointSecurity')
          })
        }
        return [ ]
      }
    },
    proxySecurityPolicies: {
      get: function () {
        if (this.policies) {
          return this.policies.filter(x => {
            return (x.policyType === 1 || x.policyType === 'ProxySecurity')
          })
        }
        return [ ]
      }
    }
  },
  data: () => ({
    previewMarkdownDialog: false,
    routes: [ ],
    showEditRouteModal: false,
    currentMethodItem: {
      root: { }
    },
    swaggerAuthTypes: [ 'None', 'Basic Auth', 'Bearer Token' ],
    apiTimeoutItems: [
      { text: '1 minute', value: 1 },
      { text: '3 minutes', value: 3 }
    ],
    additionalPolicies: [ ],
    currentAdditionalPolicySelectionId: null,
    currentAdditionalPolicy: { },
    currentAdditionalPolicyIndex: null,
    showAdditionalPolicyDialog: false,
    duplicateVariableError: false,
    securityPolicyError: false,
    swaggerEditorEnabled: false,
    securityPolicy: { },
    proxySecurityPolicy: { },
    proxySecurityPolicyError: false,
    validated: true,
    api: {
      environmentsRequiringApproval: [ ],
      path: '',
      url: '',
      useProxy: false,
      useRestrictPaths: true,
      timeout: 1,
      swaggerAuth: {
        type: 'None',
        username: '',
        password: '',
        bearerToken: ''
      },
      variables: [ ],
      openApiDocument: {
        swagger: '2.0',
        info: {
          description: '',
          title: '',
          version: '',
          contact: {
            email: ''
          }
        },
        paths: { }
      }
    },
    product: {
      name: ''
    },
    isSubmitted: false,
    productId: '',
    step: 1,
    selectedSwagger: '',
    verbs: [ 'GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'OPTIONS' ],
    parameterTypes: [ 'string', 'number', 'integer', 'boolean', 'array', 'object' ],
    prototypeContentTypeItems: ['application/json', 'application/xml'],
    newRoute: {
      path: '',
      verbs: [ ],
      name: ''
    },
    methodHeaders: [
      {
        text: 'Verb',
        align: 'left',
        sortable: false,
        value: 'verb'
      },
      {
        text: 'Path',
        align: 'left',
        sortable: false,
        value: 'path'
      },
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Edit',
        align: 'left',
        sortable: false,
        value: 'edit'
      },
      {
        text: 'Options',
        align: 'left',
        sortable: false,
        value: 'options'
      }
    ],
    environments: [ ],
    policies: [ ],
    requiredField: [
      v => !!v || 'Please Provide a value'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ],
    step4Error: ''
  }),
  methods: {
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    toggleSecret: function (index) {
      this.api.variables[index].isSecret = !this.api.variables[index].isSecret
    },
    checkMethodType: function () {
      if (this.currentMethodItem.type === 'standard') {
        delete this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties']
        delete this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties']
      } else if (this.currentMethodItem.type === 'prototype') {
        delete this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties']
      } else if (this.currentMethodItem.type === 'override') {
        delete this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties']
      }
      this.$forceUpdate()
    },
    addAdditionalPolicy: function () {
      // Get the additional policy
      var policy = this.genericPolicies.filter(x => {
        return x.id === this.currentAdditionalPolicySelectionId
      })

      // Set the property we use for policies. We do it this way so we don't edit the original object
      this.currentAdditionalPolicy = JSON.parse(JSON.stringify(policy[0]))

      // Show to policy dialog
      this.showAdditionalPolicyDialog = true
    },
    saveAdditionalPolicy: function () {
      // If we're editing a policy then we want to put it back where it's supposed to go
      if (this.currentAdditionalPolicyIndex !== null) {
        this.additionalPolicies[this.currentAdditionalPolicyIndex] = this.currentAdditionalPolicy
        this.currentAdditionalPolicyIndex = null
      } else {
        // Otherwise we have a new policy
        this.additionalPolicies.push(this.currentAdditionalPolicy)
      }
      // Reset the current additional policy for clean up reasons
      this.currentAdditionalPolicy = { }

      // Close the dialog
      this.showAdditionalPolicyDialog = false
    },
    editAdditionalPolicy: function (index) {
      this.currentAdditionalPolicy = this.additionalPolicies[index]
      this.currentAdditionalPolicyIndex = index
      this.showAdditionalPolicyDialog = true
    },
    cancelAdditionalPolicyEdit: function () {
      this.currentAdditionalPolicyIndex = null
      this.currentAdditionalPolicy = { }
      this.showAdditionalPolicyDialog = false
    },
    deleteAdditionalPolocy: function (index) {
      this.additionalPolicies.splice(index, 1)
    },
    step2Validated: function () {
      if (!this.api.openApiDocument.info.version || !this.api.openApiDocument.info.title || !this.apiPath || !this.api.path || !this.api.openApiDocument.info.description) {
        return false
      }

      if (!this.proxySecurityPolicy.id) {
        this.proxySecurityPolicyError = true
        return false
      } else {
        this.proxySecurityPolicyError = false
      }

      return true
    },
    step3Validated: function () {
      let errorCount = this.api.variables.filter(x => {
        if (!x.key || !x.environment) {
          return true
        }
      })

      if (errorCount.length > 0) {
        return false
      }

      let keyHash = this.api.variables.map(x => {
        return x.key + '-' + x.environment
      })

      var map = keyHash.reduce(function (prev, cur) {
        prev[cur] = (prev[cur] || 0) + 1
        return prev
      }, {})

      for (var property in map) {
        if (map[property] > 1) {
          return false
        }
      }
      return true
    },
    step4Validated: function () {
      let operationIds = []
      let error = false

      if (this.api && this.api.openApiDocument && this.api.openApiDocument.paths) {
        for (var path in this.api.openApiDocument.paths) {
          for (var method in this.api.openApiDocument.paths[path]) {
            if (this.api.openApiDocument.paths[path][method].operationId) {
              operationIds.push(this.api.openApiDocument.paths[path][method].operationId)
            } else {
              this.step4Error = 'A unique name is required for each route. It looks like one of your routes is missing a name. Please check the table below.'
              return false
            }
          }
        }
      }

      if (operationIds.length !== [...new Set(operationIds)].length) {
        this.step4Error = 'A unique name is required for each route. It looks like one of your routes has a duplicate name. Please check the table below.'
        return false
      } else {
        this.step4Error = ''
        return true
      }
    },
    step6Validated: function () {
      if (!this.api.url) {
        return false
      }
      return true
    },
    editRoute: function (item) {
      this.currentMethodItem = item

      if (this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties']) {
        this.currentMethodItem.type = 'prototype'
        this.currentMethodItem.prototypeContentType = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties'].contentType
        this.currentMethodItem.prototypeBody = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties'].body
      } else if (this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties']) {
        let settings = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties']
        this.currentMethodItem.type = 'override'
        this.currentMethodItem.targetUrl = settings.targetUrl
        this.currentMethodItem.useProxy = settings.useProxy !== undefined ? settings.useProxy : false
        this.currentMethodItem.timeout = settings.timeout !== undefined ? settings.timeout : 1

        if (settings.overrideSecurityPolicy) {
          // First match the policy up to the available ones using the name
          var masterPolicy = JSON.parse(JSON.stringify(this.policies.find(x => {
            return x.name === settings.overrideSecurityPolicy.policyName && x.policyType === 0
          })))

          // If we found a match continue
          if (masterPolicy) {
            if (masterPolicy.properties) {
              masterPolicy.properties.forEach(x => {
                x.value = settings.overrideSecurityPolicy.properties[x.name]
              })
            }
            this.currentMethodItem.overrideSecurityPolicy = masterPolicy
          }
        }
      } else {
        this.currentMethodItem.type = 'standard'
      }

      this.showEditRouteModal = true
    },
    saveRoute: function () {
      // Update the operational Id
      this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()].operationId = this.currentMethodItem.name

      // If the verb has changed then let's update
      if (this.currentMethodItem.verb !== this.currentMethodItem.newVerb) {
        // Check to make sure there isn't already a value for this verb
        if (this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.newVerb.toLowerCase()]) {
          // Error the Method already exists
          return
        }

        // Now update the verb
        this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.newVerb.toLowerCase()] = this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]
        // Remove the old verb
        delete this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]
        // Update the verb
        this.currentMethodItem.verb = this.currentMethodItem.newVerb
      }

      // If the path has changed then let's update
      if (this.currentMethodItem.path !== this.currentMethodItem.newPath) {
        // If there is already a path with this name then throw an error
        if (this.api.openApiDocument.paths[this.currentMethodItem.newPath]) {
          // Error the path already exists
          return
        }

        // Now update the path
        this.api.openApiDocument.paths[this.currentMethodItem.newPath] = this.api.openApiDocument.paths[this.currentMethodItem.path]
        // Remove the old path
        delete this.api.openApiDocument.paths[this.currentMethodItem.path]
        // Update the path
        this.currentMethodItem.path = this.currentMethodItem.newPath
      }

      // If the method is marked as prototyping let's add the extensions
      if (this.currentMethodItem.type === 'prototype') {
        this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties'] = {
          contentType: this.currentMethodItem.prototypeContentType,
          body: this.currentMethodItem.prototypeBody
        }
      } else if (this.currentMethodItem.type === 'override') {
        if (this.currentMethodItem.overrideSecurityPolicy) {
          let securityPolicyItem = {
            policyName: this.currentMethodItem.overrideSecurityPolicy.name,
            flowName: '',
            properties: { }
          }

          if (this.currentMethodItem.overrideSecurityPolicy.properties) {
            this.currentMethodItem.overrideSecurityPolicy.properties.forEach(x => {
              securityPolicyItem.properties[x.name] = x.value
            })
          }
          this.setOpenApiDocumentPath(securityPolicyItem)
        } else {
          this.setOpenApiDocumentPath(null)
        }
      } else {
        delete this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]['x-prototype-properties']
        delete this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties']
      }

      // Update the routes
      this.getRoutes()
      // Hide the modal
      this.showEditRouteModal = false
    },
    setOpenApiDocumentPath: function (securityPolicy) {
      this.api.openApiDocument.paths[this.currentMethodItem.path][this.currentMethodItem.verb.toLowerCase()]['x-override-target-properties'] = {
        targetUrl: this.currentMethodItem.targetUrl,
        timeout: this.currentMethodItem.timeout !== undefined ? this.currentMethodItem.timeout : 1,
        useProxy: this.currentMethodItem.useProxy !== undefined ? this.currentMethodItem.useProxy : false,
        overrideSecurityPolicy: securityPolicy
      }
    },
    removeParameterItem: function (parameter, type) {
      this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters.filter(x => {
        return (x.name !== parameter.name || x.in !== type)
      })
    },
    addParameter: function (type) {
      this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters.push({
        name: '',
        description: '',
        type: 'string',
        in: type
      })
    },
    definePathProperties: function (keypress) {
      // We only want to run on the keypresses that would result in new variables
      if (keypress.key !== '}' && keypress.key !== '{' && keypress.key !== '=') {
        return
      }
      // Split by the ? so we only check for paths
      var pathPieces = this.currentMethodItem.newPath.split('?')

      // If we have paths then let's check them
      if (pathPieces.length > 0) {
        // Get any parameters from the path
        let pathParams = pathPieces[0].match(/\{\w+\}/g)
        // If we matched any parameters then let's check them
        if (pathParams && pathParams.length > 0) {
          // For each path parameter we found
          pathParams.forEach(x => {
            // Get rid of the {} on the parameter
            let param = x.replace('{', '').replace('}', '')
            // See if the item already exists
            let pathParameters = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters
            let existingParameter = pathParameters.find(x => {
              return x.name === param && x.in === 'path'
            })
            // If the item doesn't exist then add it
            if (!existingParameter) {
              this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters.push({
                name: param,
                description: '',
                type: 'string',
                in: 'path',
                required: true
              })
            }
          })
        }
      }
      if (pathPieces.length > 1) {
        let pathParams = pathPieces[1].match(/(\w+)=/g)
        // If we matched any parameters then let's check them
        if (pathParams && pathParams.length > 0) {
          // For each path parameter we found
          pathParams.forEach(x => {
            // Get rid of the {} on the parameter
            let param = x.replace('=', '')
            // See if the item already exists
            let queryParameters = this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters
            let existingParameter = queryParameters.find(x => {
              return x.name === param && x.in === 'query'
            })
            // If the item doesn't exist then add it
            if (!existingParameter) {
              this.currentMethodItem.root[this.currentMethodItem.verb.toLowerCase()].parameters.push({
                name: param,
                description: '',
                type: 'string',
                in: 'query'
              })
            }
          })
        }
      }
    },
    addRoute: function () {
      if (!this.api.openApiDocument.paths[this.newRoute.path]) {
        this.api.openApiDocument.paths[this.newRoute.path] = { }
      }

      this.newRoute.verbs.forEach(x => {
        if (this.api.openApiDocument.paths[this.newRoute.path][x.toLowerCase()]) {
          if (!this.api.openApiDocument.paths[this.newRoute.path][x.toLowerCase()].parameters) {
            this.api.openApiDocument.paths[this.newRoute.path][x.toLowerCase()].parameters = [ ]
          }
          // Error this path already exists
          return false
        } else {
          this.api.openApiDocument.paths[this.newRoute.path][x.toLowerCase()] = {
            description: '',
            operationId: x + '-' + this.newRoute.name,
            parameters: [ ]
          }

          if (x === 'POST' || x === 'PUT') {
            this.api.openApiDocument.paths[this.newRoute.path][x.toLowerCase()].parameters.push({
              name: 'body',
              description: 'payload',
              type: 'object',
              in: 'body'
            })
          }

          var verb = x.toLowerCase()
          // Split by the ? so we only check for paths
          var pathPieces = this.newRoute.path.split('?')

          // If we have paths then let's check them
          if (pathPieces.length > 0) {
            // Get any parameters from the path
            let pathParams = pathPieces[0].match(/\{\w+\}/g)
            // If we matched any parameters then let's check them
            if (pathParams && pathParams.length > 0) {
              // For each path parameter we found
              pathParams.forEach(y => {
                // Get rid of the {} on the parameter
                let param = y.replace('{', '').replace('}', '')

                // See if the item already exists
                let pathParameters = this.api.openApiDocument.paths[this.newRoute.path][verb].parameters

                // If the item doesn't exist then add it
                pathParameters.push({
                  name: param,
                  description: '',
                  type: 'string',
                  in: 'path',
                  required: true
                })
              })
            }
          }
          if (pathPieces.length > 1) {
            let pathParams = pathPieces[1].match(/(\w+)=/g)
            // If we matched any parameters then let's check them
            if (pathParams && pathParams.length > 0) {
              // For each path parameter we found
              pathParams.forEach(y => {
                // Get rid of the {} on the parameter
                let param = y.replace('=', '')
                // See if the item already exists
                let queryParameters = this.api.openApiDocument.paths[this.newRoute.path][verb].parameters
                queryParameters.push({
                  name: param,
                  description: '',
                  type: 'string',
                  in: 'query'
                })
              })
            }
          }
        }
      })

      this.newRoute = {
        name: '',
        verbs: [ ],
        path: ''
      }
      this.getRoutes()
    },
    deleteRoutePrompt: function (item) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this route?',
        confirmMethod: () => {
          this.deleteRoute(item)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteRoute: function (item) {
      delete this.api.openApiDocument.paths[item.path][item.verb.toLowerCase()]
      this.getRoutes()
    },
    getRoutes: function () {
      let routeNames = Object.keys(this.api.openApiDocument.paths).map(key => ({
        name: key,
        value: this.api.openApiDocument.paths[key]
      }))

      let methods = [ ]
      let verbs = routeNames.forEach(x => {
        Object.keys(x.value).map(key => {
          methods.push({
            name: x.value[key].operationId,
            path: x.name,
            verb: key.toUpperCase(),
            newVerb: key.toUpperCase(),
            newPath: x.name,
            root: x.value,
            key: key + '-' + x.name
          })
        })
      })

      this.routes = methods
    },
    groupBy: function (objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, { })
    },
    validate: function () {
      // First set validated to true so we can re-validate
      this.validated = true

      // Set the proxy security error to false
      this.proxySecurityPolicyError = false
      if (!this.proxySecurityPolicy.id) {
        this.proxySecurityPolicyError = true
        this.validated = false
      }

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      if (!this.step3Validated) {
        this.validated = false
      }

      if (!this.step4Validated()) {
        this.validated = false
      }

      return this.validated
    },
    closeSwaggerEditor: function () {
      this.swaggerEditorEnabled = false
      let yaml = window.localStorage.getItem('swagger-editor-content')
      let swagger = jsyaml.safeLoad(yaml)

      if (!swagger.info.title) {
        swagger.info.title = ''
      }

      if (!swagger.info.description) {
        swagger.info.description = ''
      }

      this.api.openApiDocument = swagger
      this.getRoutes()
    },
    openSwaggerEditor: function () {
      this.swaggerEditorEnabled = true
      let yaml = jsyaml.safeDump(this.api.openApiDocument)
      window.localStorage.setItem('swagger-editor-content', yaml)
      this.$nextTick(() => {
        SwaggerEditor({
          dom_id: '#swagger-editor',
          layout: 'EditorLayout',
          plugins: { },
          presets: [
            SwaggerUI.presets.apis,
            SwaggerEditor.SwaggerEditorStandalonePreset
          ]
        })
      })
    },
    addVariable: function () {
      this.api.variables.push(
        {
          isSecret: false
        }
      )
    },
    deleteVariable: function (index) {
      this.api.variables.splice(index, 1)
    },
    getEnvironments: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/environments/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.product = response.data
            if (this.product.isSubscriberOnly) {
              this.$store.commit('showErrorDialog', {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'This product can only be used to subscribe to APIs.'
                    }
                  }
                },
                persistErrorDialog: true,
                retryMethod: () => this.getProduct()
              })
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    processPolicies: function () {
      let policies = [ ]
      // Create the security policy item from the UI policy object and push it into the policy array
      if (this.securityPolicy.id) {
        let securityPolicyItem = {
          policyName: this.securityPolicy.name,
          flowName: '',
          properties: { }
        }

        if (this.securityPolicy.properties) {
          this.securityPolicy.properties.forEach(x => {
            securityPolicyItem.properties[x.name] = x.value
          })
        }

        policies.push(securityPolicyItem)
      }

      // Create the front end security policy from the UI policy object and push it into the policy array
      if (this.proxySecurityPolicy.id) {
        let proxySecurityPolicyItem = {
          policyName: this.proxySecurityPolicy.name,
          flowName: '',
          properties: { }
        }

        if (this.proxySecurityPolicy.properties) {
          this.proxySecurityPolicy.properties.forEach(x => {
            proxySecurityPolicyItem.properties[x.name] = x.value
          })
        }

        policies.push(proxySecurityPolicyItem)
      }

      if (this.additionalPolicies.length > 0) {
        this.additionalPolicies.forEach(x => {
          let policy = {
            policyName: x.name,
            flowName: x.flowName,
            properties: { }
          }

          if (x.properties) {
            x.properties.forEach(y => {
              policy.properties[y.name] = y.value
            })
          }

          policies.push(policy)
        })
      }

      return policies
    },
    submit: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      this.api.productId = this.productId
      this.api.policies = this.processPolicies()
      axios(
        `${process.env.VUE_APP_BASEURL}/api`,
        {
          method: 'POST',
          data: JSON.stringify(this.api),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/apis/${response.data.id}`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.submit() })
            return error
          })
    },
    selectSwaggerOption: function (option) {
      this.selectedSwagger = option
      if (option === 'new') {
        this.step = 2
      }
    },
    processSwagger: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/api/swagger?url=${this.api.originalSwaggerUrl}`,
        {
          method: 'POST',
          data: JSON.stringify(this.api.swaggerAuth),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.api = response.data
            this.getRoutes()
            this.step = 2
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.processSwagger() })
            return error
          })
    },
    getPolicies: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/policy?productGuid=${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.policies = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPolicies() })
            return error
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'Api Builder',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/builder`
          }
        ])
      })
      this.getEnvironments()
      this.getPolicies()
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style>

.theme--dark .selected {
  background-color: #757575 !important;
}

.selected {
  background-color:#D9D9D6 !important;
}

.verb-selection {
  width: 105px;
  text-align: center;
}

.SplitPane {
  overflow-y: scroll !important;
}
</style>
