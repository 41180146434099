<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="metric-datetime">
          <div class="datetime-label">
              From:
          </div>
            <date-time
              :error="dateError"
              type="datetime"
              v-model="from"
              input-id="fromTimePicker"
              value-zone="America/Chicago"
              :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
              :phrases="{ok: 'Continue', cancel: 'Exit'}"
              :hour-step="1"
              :minute-step="15"
              :week-start="7"
              use12-hour
              auto
              ></date-time>
        </div>
      </v-col>
      <v-col>
          <div class="metric-datetime">
        <div class="datetime-label">
            To:
        </div>
        <date-time
          :error="dateError"
          type="datetime"
          v-model="to"
          input-class="timePicker"
          value-zone="America/Chicago"
          :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
          :phrases="{ok: 'Continue', cancel: 'Exit'}"
          :hour-step="1"
          :minute-step="15"
          :week-start="7"
          use12-hour
          auto
          ></date-time>
        </div>
      </v-col>
      <v-col>
        <div class="metric-datetime">
          <div class="datetime-label">
            Environment:
          </div>
        <v-select style="min-width: 200px" solo v-model="selectedEnvironment" :items="environments" item-text="displayName" item-value="id"></v-select>
        </div>
      </v-col>
      <v-col style="margin-left: 15px;">
        <v-btn large elevation="2" @click="getMetricsForApi" :disabled="dateError || loading">Submit</v-btn>
      </v-col>
      <v-col class="text-center" v-if="dateError">
        <span class="error--text">Make sure a from and to date are selected, in the right order, and are within 60 days of each other</span>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Total Requests
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <single-metric-component label="Requests" :chart-data="overallStats" :time-unit="timeUnit" :class="!loading && hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Total Failed Requests
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <single-metric-component label="Failures" :chart-data="overallErrors" :time-unit="timeUnit" :class="!loading && hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Requests Per Subscriber
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <multi-metric-component label="Subscriber" :chart-data="requestsPerSubscriber" :time-unit="timeUnit" :class="!loading && hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Failed Requests Per Subscriber
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <multi-metric-component label="Subscriber" :chart-data="errorsPerSubscriber" :time-unit="timeUnit" :class="!loading & hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Requests Per Endpoint
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <multi-metric-component label="Endpoint" :chart-data="requestsPerEndpoint" :time-unit="timeUnit" :class="!loading && hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Failed Requests Per Endpoint
          </v-card-title>
          <v-card-text>
            <v-row v-if="!hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Click the submit button above to load metrics</h3>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col cols="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="loading && hasMetricsLoaded">
              <v-col class="text-center">
                <h3>Loading metrics. Please wait this may take a few seconds</h3>
              </v-col>
            </v-row>
            <multi-metric-component label="Endpoint" :chart-data="errorsPerEndpoint" :time-unit="timeUnit" :class="!loading && hasMetricsLoaded ? 'showChart' : 'hideChart'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'
import singleMetricComponent from '@/components/singleMetricComponent'
import multiMetricComponent from '@/components/multiMetricComponent'

export default {
  name: 'metrics-component',
  props: ['apiId'],
  components: {
    'date-time': Datetime,
    'single-metric-component': singleMetricComponent,
    'multi-metric-component': multiMetricComponent
  },
  watch: {
    from: function () {
      this.isDateError()
    },
    to: function () {
      this.isDateError()
    },
    metrics: function (val) {
      if (this.metrics && this.metrics.overallStats && this.metrics.overallStats[0] && this.metrics.overallStats[0].metrics && this.metrics.overallStats[0].metrics[0].values) {
        this.overallStats = this.metrics.overallStats[0].metrics[0].values
      }

      if (this.metrics && this.metrics.overallErrors && this.metrics.overallErrors[0] && this.metrics.overallErrors[0].metrics && this.metrics.overallErrors[0].metrics[0].values) {
        this.overallErrors = this.metrics.overallErrors[0].metrics[0].values
      }

      if (this.metrics && this.metrics.requestsPerSubscriber) {
        this.requestsPerSubscriber = this.metrics.requestsPerSubscriber
      }

      if (this.metrics && this.metrics.errorsPerSubscriber) {
        this.errorsPerSubscriber = this.metrics.errorsPerSubscriber
      }

      if (this.metrics && this.metrics.requestsPerEndpoint) {
        this.requestsPerEndpoint = this.metrics.requestsPerEndpoint
      }

      if (this.metrics && this.metrics.errorsPerEndpoint) {
        this.errorsPerEndpoint = this.metrics.errorsPerEndpoint
      }
    }
  },
  computed: {
  },
  data () {
    return {
      dateError: false,
      to: null,
      from: null,
      timeUnit: 0,
      environments: [ ],
      selectedEnvironment: null,
      overallStats: [ ],
      overallErrors: [ ],
      requestsPerSubscriber: [ ],
      errorsPerSubscriber: [ ],
      requestsPerEndpoint: [ ],
      errorsPerEndpoint: [ ],
      loading: false,
      hasMetricsLoaded: false,
      metrics: {
        errorsPerEndpoint: null,
        errorsPerSubscriber: null,
        overallErrors: null,
        overallStats: null,
        requestsPerEndpoint: null,
        requestsPerSubscriber: null
      }
    }
  },
  methods: {
    isDateError: function () {
      let timeDiff = moment(new Date(this.to)).diff(moment(new Date(this.from)), 'days')
      if (this.from && this.to && (timeDiff > 60 || timeDiff < 0)) {
        this.dateError = true
        return true
      } else {
        this.dateError = false
        return false
      }
    },
    getMetricsForApi: function () {
      if (this.isDateError()) {
        return false
      }

      this.loading = true
      this.hasMetricsLoaded = true
      if (new Date(this.to) - new Date(this.from) > 86400000) {
        this.timeUnit = 0
      } else {
        this.timeUnit = 1
      }

      if (moment(new Date(this.from)).diff(moment(new Date(this.to)), 'days') > 60) {
        this.dateError = true
        return false
      } else {
        this.dateError = false
      }

      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/environments/${this.selectedEnvironment}/metrics?to=${this.to}&from=${this.from}&timeUnit=${this.timeUnit}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.loading = false
            this.metrics = response.data
          },
          (error) => {
            this.loading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMetricsForApi() })
            return error
          })
    },
    getEnvironments: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/environments/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
            this.selectedEnvironment = this.environments[0].id
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    }
  },
  mounted () {
    this.to = moment(new Date()).format()
    this.from = moment(new Date()).subtract(12, 'h').format()
    this.getEnvironments()
  }
}
</script>

<style>
.vdatetime-input {
  max-width: 250px;
  display: block;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
     -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
      -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
       -o-transition: border linear 0.2s, box-shadow linear 0.2s;
          transition: border linear 0.2s, box-shadow linear 0.2s;
}

.metric-datetime {
  display: flex;
}

.datetime-label {
  margin-top: 10px;
  margin-right: 10px;
  font-size: 14pt;
}

.showChart {
    display: block;
  }

.hideChart {
  display: none;
}
</style>
