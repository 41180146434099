var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("API Environments:")])])],1),_c('v-row',{attrs:{"id":"v-step-2"}},[(!_vm.deployments.environments)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-skeleton-loader',{staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","max-height":"142","type":"card"}}),_c('v-skeleton-loader',{staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","max-height":"142","type":"card"}}),_c('v-skeleton-loader',{staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","max-height":"142","type":"card"}}),_c('v-skeleton-loader',{staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","max-height":"142","type":"card"}}),_c('v-skeleton-loader',{staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","max-height":"142","type":"card"}})],1)],1):_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.deployments.environments),function(deployment,index){return _c('v-card',{key:index,staticClass:"ma-2 pa-1",attrs:{"max-width":"350","min-width":"350","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(deployment.environment.displayName))]),(deployment.environment.isInternetAccessible)?_c('v-list-item-subtitle',[_vm._v("Internet Facing - Deploy to request approval")]):_c('v-list-item-subtitle')],1),_c('v-list-item-avatar',{attrs:{"id":"v-step-4"}},[(_vm.currentlyDeploying.includes(deployment.environment.id))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-progress-circular',{attrs:{"indeterminate":"","rotate":"","size":25,"width":3,"color":"light-blue"}})],1)]}}],null,true)},[_c('span',[_vm._v("Working, Please wait")])])]:[(_vm.environmentIncludesRevisionInPending(deployment))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.cancelRequestConfirm(deployment.environment.id)}}},on),[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-timer-sand-empty")])],1)]}}],null,true)},[_c('span',[_vm._v("The curent revision you are viewing is pending approval")])]):(_vm.environmentIncludesRevision(deployment))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("The curent revision you are viewing has been deployed to this environment")])]):(_vm.environmentsIncludeVersion(deployment))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-alert")])],1)]}}],null,true)},[_c('span',[_vm._v("A different revision that what you are viewing has been deployed to this environment")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deployApi(deployment.environment.id, deployment.environment.isInternetAccessible)}}},on),[_c('v-icon',[_vm._v("mdi-circle-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("This API version has not yet been deployed to this environment.")])])]],2)],1),_c('v-card-actions',{attrs:{"id":"v-step-3"}},[(!_vm.environmentIncludesRevision(deployment) && !_vm.environmentIncludesRevisionInPending(deployment))?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.deployApi(deployment.environment.id, deployment.environment.isInternetAccessible)}}},[_vm._v("Deploy")]):_vm._e(),(_vm.environmentIncludesRevisionInPending(deployment))?_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.cancelRequestConfirm(deployment.environment.id)}}},[_vm._v("Cancel Request")]):_vm._e(),(_vm.environmentIncludesRevision(deployment) && !_vm.environmentIncludesRevisionInPending(deployment))?_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.unDeployConfirm(deployment.environment.id)}}},[_vm._v("Undeploy")]):_vm._e()],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }