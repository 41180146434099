<script>
import { Line, mixins } from 'vue-chartjs'
import moment from 'moment'

export default {
  name: 'timeline-graph-component',
  extends: Line,
  props: ['chartData', 'options', 'timeUnit', 'label'],
  watch: {
    chartData: function (val) {
      // Check that data is available
      if (this.chartData) {
        // Create a holder for our data
        let data = [ ]
        // Loop through the data to build the items
        this.chartData.forEach(item => {
          // Calculate time
          // let time = new Date(item.timestamp)
          var utcTime = moment.utc(item.timestamp).toDate()
          var local = moment(utcTime).local()
          // Add in the data
          data.push({
            x: local,
            y: parseInt(item.value)
          })
        })
        let chart = {
          datasets: [
            {
              label: this.label,
              borderColor: 'rgba(255, 99, 132)',
              fill: false,
              data: data,
              lineTension: 0
            }
          ]
        }
        let unit = 'day'
        if (this.timeUnit === 0) {
          unit = 'day'
        } else if (this.timeUnit === 1) {
          unit = 'hour'
        }
        let options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: unit
              }
            }]
          }
        }
        // Render the chart
        this.renderChart(chart, options)
      }
    }
  },
  mounted () {
  }
}
</script>

<style>
</style>
