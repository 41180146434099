<template>
     <v-autocomplete
        v-model="model"
        :rules="rules"
        :items="userSearchResults"
        :loading="userSearchLoading"
        :search-input.sync="userSearchSync"
        :label="label"
        item-text="Value"
        placeholder="Start typing an 34 or lastname"
        :disabled="disabled"
        clearable
        :filter="filterObject"
        return-object
    >
    <template slot="item" slot-scope="data">
        {{ data.item.Key }} - {{ data.item.Value }}
    </template>
    </v-autocomplete>
</template>

<script>
import axios from 'axios'

export default {
  name: 'user-search-component',
  props: ['value', 'disabled', 'rules', 'label', 'noClear'],
  watch: {
    userSearchSync: {
      handler () {
        if (this.userSearchSync) {
          if (this.model && this.userSearchSync === this.model) {
            return
          }
          clearTimeout(this.userSeachTimeout)

          // Make a new timeout set to go off in 800ms
          this.userSeachTimeout = setTimeout(() => {
            this.searchForUser()
          }, 500)
        } else {
          this.userSearchResults = [ ]
          this.model = ''
        }
      }
    },
    model: {
      handler () {
        this.$nextTick(() => {
          if (!this.noClear) {
            this.model = null
          }
        })
      }
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data () {
    return {
      userSearchResults: [],
      userSearchLoading: false,
      userSeachTimeout: null,
      userSearchSync: null
    }
  },
  methods: {
    filterObject (item, queryText, itemText) {
      return (item.Key.toLowerCase().includes(queryText.toLowerCase()) || item.Value.toLowerCase().includes(queryText.toLowerCase()))
    },
    searchForUser () {
      this.userSearchLoading = true
      axios(`${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${this.userSearchSync}`, { method: 'GET', params: { } })
        .then((response) => {
          this.userSearchLoading = false
          this.userSearchResults = response.data
        }, (error) => {
          if (error) {
            this.userSearchLoading = false
          }
        })
    }
  },
  beforeMount () {
    if (this.value) {
      this.userSearchResults = [this.value]
    }
  }
}
</script>

<style>
</style>
