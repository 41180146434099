<script>
import { Line, mixins } from 'vue-chartjs'
import moment from 'moment'

export default {
  name: 'timeline-graph-multi-component',
  extends: Line,
  props: ['chartData', 'options', 'timeUnit', 'label'],
  watch: {
    chartData: function (val) {
      // Check that data is available
      if (this.chartData) {
        let datasets = [ ]
        this.chartData.forEach(dimension => {
          // Create a holder for our data
          let data = [ ]
          // Loop through the data to build the items
          dimension.metrics[0].values.forEach(item => {
            // Calculate time
            let time = new Date(item.timestamp)
            // Add in the data
            data.push({
              x: time,
              y: parseInt(item.value)
            })
          })
          datasets.push({
            label: dimension.name,
            borderColor: this.random_rgb(),
            fill: false,
            data: data,
            lineTension: 0
          })
        })

        let chart = {
          datasets: datasets
        }
        let unit = 'day'
        if (this.timeUnit === 0) {
          unit = 'day'
        } else if (this.timeUnit === 1) {
          unit = 'hour'
        }

        let options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: unit
              }
            }]
          }
        }
        // Render the chart
        this.renderChart(chart, options)
      }
    }
  },
  methods: {
    random_rgb: function () {
      var num = Math.round(0xffffff * Math.random())
      var r = num >> 16
      var g = num >> 8 & 255
      var b = num & 255
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
  },
  mounted () {
  }
}
</script>

<style>
</style>
