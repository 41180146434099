<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" id="v-step-0">
          <h1 style="font-size: 36px">APIs</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Consumer%29+-+Search+and+Subscribe+and+Unsubscribe+to+an+API">Need help using APIs</a>
      </v-col>
    </v-row>
    <v-row class="pb-0 mb-0">
        <v-col cols="12" sm="12" md="5" id="v-step-1" class="pb-0 mb-0">
          <v-text-field
            label="Name, Description, Tag, Product Name"
            hint="Search for apis by name, description, tag, or product name"
            persistent-hint
            prepend-inner-icon="mdi-magnify"
            placeholder=""
            solo
            clearable
            v-model="search"
            :loading="isApiLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0 mb-0">
          <v-select
            solo
            hint="Sort Ordering"
            persistent-hint
            :items="orderByOptions"
            item-text="itemText"
            item-value="itemValue"
            v-model="orderBy"
            label="Sort By"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" class="pb-0 mb-0">
          <v-select
            solo
            :items="products"
            item-text="displayName"
            item-value="id"
            v-model="selectedProduct"
            hint="Filter by Product"
            persistent-hint
            label="Product"
            :loading="loadingProducts"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" class="pb-0 mb-0">
          <v-select
            solo
            :items="affiliates"
            item-text="displayName"
            item-value="id"
            v-model="selectedAffiliate"
            hint="Filter by Affiliate"
            persistent-hint
            label="Affiliate"
            :loading="loadingAffiliates"
            clearable
          ></v-select>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row class="mt-3" v-if="isApiLoading && apis.length == 0">
      <api-skeleton />
    </v-row>
    <v-row>
      <v-col>
        <span v-if="apis.length === 0 && !isApiLoading">No Apis found</span>
        <v-row>
          <v-col cols="12" xl="4" lg="6" v-for="(item) in apis" :key="item.apiId">
            <v-card
              class="mx-auto"
              min-height="170"
              hover
              ripple
              @click="routeToApi(item)"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            >
              <v-list-item>
                <v-list-item-avatar><v-img src="@/assets/api.png" /></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{ item.displayName }}</v-list-item-title>
                  <v-list-item-subtitle>by <a @click.stop="routeToProduct(item.productId)">{{ item.productName }}</a></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.tags">
                    <v-chip
                      v-for="(tag, tagIndex) in item.tags"
                      :key="tagIndex"
                      class="ma-1 pa-3"
                    >
                      {{tag}}
                    </v-chip>
                    <br/>
                    <v-chip
                      v-for="(affiliate, affiliateIndex) in item.productAffiliateIds.filter(x => item.teamAffiliates && item.teamAffiliates.indexOf(x) < 0)"
                      :key="affiliateIndex + 'productaffiliate'"
                      class="ma-1 pa-3"
                      color="blue-grey"
                      dark
                    >
                      {{ getAffiliateName(affiliate) }}
                    </v-chip>
                    <v-chip
                      v-for="(affiliate, affiliateTeamIndex) in item.teamAffiliateIds"
                      :key="affiliateTeamIndex + 'teamaffiliate'"
                      class="ma-1 pa-3"
                      color="blue-grey"
                      dark
                    >
                      {{ getAffiliateName(affiliate) }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="apiCount > 1">
          <v-col>
            <v-pagination
              v-model="apiPage"
              :length="apiCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import apiSkeleton from './components/apiSkeleton'

export default {
  components: {
    'api-skeleton': apiSkeleton
  },
  name: 'ApiList',
  computed: {
    panelDisplay: {
      get: function () {
        return [...Array(this.productsWithApis.length)].map((_, i) => i)
      },
      set: function (val) {

      }
    }
  },
  watch: {
    apiPage: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getApis()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getApis()
      }, 500)
    },
    orderBy: function () {
      this.page = 1
      this.getApis()
    },
    selectedTeam: function () {
      this.page = 1
      this.getApis()
    },
    selectedProduct: function () {
      this.page = 1
      this.getApis()
    },
    selectedAffiliate: function () {
      this.page = 1
      this.getApis()
    }
  },
  data: () => ({
    isApiLoading: true,
    affiliates: [ ],
    products: [ ],
    teams: [ ],
    selectedProduct: null,
    selectedTeam: null,
    selectedAffiliate: null,
    searchTimeout: null,
    apiPage: 1,
    apiLimit: 10,
    apiCount: 0,
    tags: '',
    apis: [ ],
    search: '',
    orderBy: 'name',
    orderByOptions: [
      {
        itemText: 'Api Name (A - Z)',
        itemValue: 'name'
      },
      {
        itemText: 'Product Name (A - Z)',
        itemValue: 'product'
      },
      {
        itemText: 'Creation Date (Descending)',
        itemValue: 'date'
      }
    ],
    loadingTeams: false,
    loadingAffiliates: false,
    loadingProducts: false
  }),
  methods: {
    getAffiliateName: function (affiliateId) {
      if (this.affiliates) {
        var affiliate = this.affiliates.find(x => x.id === affiliateId)
        if (affiliate) {
          return affiliate.displayName
        }
      }
      return ''
    },
    routeToProduct: function (productId) {
      this.$router.push(`/product-view/${productId}`)
    },
    routeToApi: function (item) {
      this.$router.push(`/apis/details/${item.apiId}`)
    },
    getApis: function () {
      this.isApiLoading = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/api?page=${this.apiPage}&limit=${this.apiLimit}&search=${this.search}&orderBy=${this.orderBy}&team=${this.selectedTeam}&product=${this.selectedProduct}&affiliate=${this.selectedAffiliate}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isApiLoading = false
            this.apis = response.data.items
            this.apiCount = Math.ceil(response.data.totalCount / this.apiLimit)
          },
          (error) => {
            this.isApiLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getProducts: function () {
      this.loadingProducts = true
      axios(
        `${process.env.VUE_APP_BASEURL}/product?showProductsWithoutPublicApis=false`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.products = response.data.items
            this.loadingProducts = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProducts() })
            this.loadingProducts = false
          })
    },
    getAffiliates: function () {
      this.loadingAffiliates = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
            this.loadingAffiliates = false
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    }
  },
  mounted: function () {
    this.$store.commit('breadcrumbs', [
      {
        text: 'Apis',
        exact: true,
        disabled: false,
        to: '/apis'
      }
    ])
    this.getApis()
    this.getProducts()
    this.getAffiliates()
  }
}
</script>

<style>
.api-name {
  font-size: 20px;
}
</style>
