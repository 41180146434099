<template>
  <v-container>
    <div v-show="swaggerEditorEnabled" class="swagger-editor-container">
      <v-row style="margin-top: -25px; margin-bottom: -10px;">
          <v-col cols="12" md="12" class="d-flex justify-center">
            <v-btn small @click="closeSwaggerEditor">Save and close editor</v-btn>
          </v-col>
      </v-row>
      <v-row style="margin-top: -20px; margin-bottom: -38px">
          <v-col cols="12" md="12" class="d-flex justify-center">
            <p>This swagger may contain secrets, please do not share with others</p>
          </v-col>
      </v-row>
      <div id="swagger-editor"></div>
    </div>
    <div v-show="!swaggerEditorEnabled">
      <v-row>
        <v-col cols="12" md="11">
              <h1 id="v-step-0">{{ apiMetaData.displayName }} {{ selectedVersion }}</h1>
              <v-skeleton-loader
                v-if="!apiMetaData.displayName"
                class="mx-auto"
                min-width="100"
                type="heading"
              ></v-skeleton-loader>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="1">
          <v-tooltip bottom v-if="health === 'Healthy'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon x-large color="success" v-bind="attrs" v-on="on">mdi-shield-check</v-icon>
            </template>
            <span>This API's healthchecks are all healthy</span>
          </v-tooltip>
          <v-tooltip bottom v-if="health === 'Unhealthy'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon x-large color="error" v-bind="attrs" v-on="on">mdi-shield-alert</v-icon>
            </template>
            <span>This API has one or more failing healthchecks</span>
          </v-tooltip>
          <v-tooltip bottom v-if="health === 'NoTests'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon x-large color="warning" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>This API has no healthchecks, consider adding healthchecks to be notified of API issues</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <div class="d-inline pa-2 version-selection mb-3" id="v-step-1">
          <v-select label="Selected Version" outlined @change="getApiByVersion()" v-model="selectedVersion" :items="apiMetaData.availableVersions"></v-select>
          <v-btn :href="'/apis/details/' + apiId" target="_blank">
            <v-img style="max-height: 25px; max-width: 25px; margin-right: 5px;" left src="@/assets/swagger.jpg" />  View Api
          </v-btn>
          <v-btn style="margin-left: 15px;" @click="downloadJson">
            <v-icon left>mdi-download</v-icon>  Download DevOps Json
          </v-btn>
        </div>
      </v-row>
      <v-divider/>
      <v-row v-if="isLoading">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"
            class="mt-2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-tabs
            left
            dark
            v-model="tabView"
          >
            <v-tab>
              Deployments
            </v-tab>
            <v-tab>
              Subscriptions
            </v-tab>
            <v-tab>
              Settings
            </v-tab>
            <v-tab>
              Edit Api
            </v-tab>
            <v-tab>
              Health Checks
            </v-tab>
            <v-tab>
              Metrics (Beta)
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabView">
            <v-tab-item>
              <deployment-component :deployments="deployments" :api-meta-data="apiMetaData" :api-id="apiId" :api="api" @deployEvent="getDeployments()" />
            </v-tab-item>
            <v-tab-item>
              <scriptions-component :api-id="apiId" :deployments="deployments" />
            </v-tab-item>
            <v-tab-item>
              <settings-component :api-meta-data="apiMetaData" :deployments="deployments" :api="api" :api-id="apiId"/>
            </v-tab-item>
            <v-tab-item>
              <edit-component :swaggerEditorEvent="swaggerEditorEvent" @updateApi="updateApi" @openSwaggerEditor="openSwaggerEditor" :api="api" :api-meta-data="apiMetaData" :deployments="deployments" :api-id="apiId" @apiUpdated="apiUpdated"/>
            </v-tab-item>
            <v-tab-item>
              <health-check-component :api-id="apiId" />
            </v-tab-item>
            <v-tab-item>
              <metrics-component :api-id="apiId" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="displayDownloadModal" width="500">
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Downloading Report
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="d-flex justify-center">
              <v-col class="d-flex justify-center">
                <v-progress-circular
                  :width="3"
                  :size="100"
                  color="red"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col>
                <h2 class="text-center">Your report will begin downloading in a moment</h2>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="displayDownloadModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

import { mapGetters } from 'vuex'

import deploymentsComponent from './components/deploymentsComponent'
import subscriptionsComponent from './components/subscriptionsComponent'
import settingsComponent from './components/settingsComponent'
import editComponent from './components/editComponent'
import healthCheckComponent from './components/healthCheckComponent'
import metricsComponent from './components/metricsComponent'
import SwaggerEditor, { plugins } from 'swagger-editor'
import SwaggerUI from 'swagger-ui'
import 'swagger-editor/dist/swagger-editor.css'
import jsyaml from 'js-yaml'

export default {
  name: 'EditApi',
  components: {
    'metrics-component': metricsComponent,
    'deployment-component': deploymentsComponent,
    'scriptions-component': subscriptionsComponent,
    'settings-component': settingsComponent,
    'edit-component': editComponent,
    'health-check-component': healthCheckComponent
  },
  computed: {
    ...mapGetters(['adminSettings']),
    isLoading: {
      get: function () {
        let isLoading = !this.loadingApiMetadata && !this.loadingApi
        return !isLoading
      }
    }
  },
  data: () => ({
    displayDownloadModal: false,
    reportId: null,
    swaggerEditorEnabled: false,
    swaggerEditorEvent: false,
    checkInterval: null,
    isSubmitted: false,
    tabView: 0,
    apiId: '',
    health: null,
    selectedVersion: null,
    product: {},
    isLoaded: true,
    loadingDeployments: true,
    loadingApiMetadata: true,
    loadingApi: true,
    loadingHealthchecks: true,
    deploymentsUpdated: true,
    api: {
      variables: [ ],
      path: '',
      revision: null,
      swaggerAuth: {
        type: 'None',
        username: '',
        password: '',
        bearerToken: ''
      },
      openApiDocument: {
        info: {
          description: '',
          title: '',
          version: '',
          contact: {
            email: ''
          },
          paths: { }
        }
      }
    },
    apiMetaData: {
      basePath: ''
    },
    requiredField: [
      v => !!v || 'Please Provide a value'
    ],
    deployments: { }
  }),
  methods: {
    updateApi: function (api) {
      this.api = api
    },
    getApiHealthAtAGlance: function () {
      this.loadingHealthchecks = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${this.apiId}/glance`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.health = response.data
            this.loadingHealthchecks = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiHealthAtAGlance() })
          })
    },
    apiUpdated: function (newApi) {
      this.api = newApi
      this.getApiMetaData()
      this.tabView = 0
    },
    getDeployments: function () {
      this.loadingDeployments = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/apis/${this.apiId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.deployments = response.data
            this.loadingDeployments = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getDeployments() })
          })
    },
    getApiByVersion: function () {
      this.loadingApi = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/versions/${this.selectedVersion}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.api = response.data
            if (this.api.originalSwaggerUrl) {
              this.selectedSwagger = 'existing'
            } else {
              this.selectedSwagger = 'new'
            }

            if (this.api.swaggerAuth === null) {
              this.api.swaggerAuth = {
                type: 'None',
                username: '',
                password: '',
                bearerToken: ''
              }
            }
            this.loadingApi = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiByVersion() })
          })
    },
    getApiMetaData: function () {
      this.loadingApiMetadata = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/meta`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apiMetaData = response.data
            this.loadingApiMetadata = this.apiMetaData.availableVersions
            if (this.apiMetaData.availableVersions) {
              this.selectedVersion = this.apiMetaData.availableVersions[this.apiMetaData.availableVersions.length - 1]
              this.loadingApiMetadata = false
              // on initial page load this will set this.api
              this.getApiByVersion()
            } else {
              alert('Could not find any versions for this API. You are about to be redirected')
              this.$router.push(`/products`)
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiMetaData() })
          })
    },
    getApiInfo: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.api = response.data
            this.getProduct()
            if (this.api.originalSwaggerUrl) {
              this.selectedSwagger = 'existing'
            } else {
              this.selectedSwagger = 'new'
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApiInfo() })
          })
    },
    downloadJson: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/revisions/${this.api.revision}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            response.data.policies = response.data.policies.filter(x => {
              return x.policyName !== 'Scoped-Environment-Variables-Policy' && x.policyName !== 'Allow-Cors-Policy' && x.policyName !== 'Override-Target-Url-Policy'
            })

            const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data, null, 2)]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.api.displayName}.json`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadSwaggerJson() })
            return error
          })
    },
    setupHealthcheck: function () {
      this.getApiHealthAtAGlance()
      this.checkInterval = setInterval(() => {
        this.getApiHealthAtAGlance()
      }, 60000)
    },
    closeSwaggerEditor: function () {
      this.swaggerEditorEnabled = false
      let yaml = window.localStorage.getItem('swagger-editor-content')
      let swagger = jsyaml.safeLoad(yaml)

      if (!swagger.info.title) {
        swagger.info.title = ''
      }

      if (!swagger.info.description) {
        swagger.info.description = ''
      }

      this.api.openApiDocument = swagger
      this.swaggerEditorEvent = !this.swaggerEditorEvent
    },
    openSwaggerEditor: function () {
      this.swaggerEditorEnabled = true
      let yaml = jsyaml.safeDump(this.api.openApiDocument)
      window.localStorage.setItem('swagger-editor-content', yaml)
      this.$nextTick(() => {
        SwaggerEditor({
          dom_id: '#swagger-editor',
          layout: 'EditorLayout',
          plugins: { },
          presets: [
            SwaggerUI.presets.apis,
            SwaggerEditor.SwaggerEditorStandalonePreset
          ]
        })
      })
    },
    downloadHtmlResultsAsync: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/html/${this.reportId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob' // important
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `report.html`)
            document.body.appendChild(link)
            link.click()
            this.displayDownloadModal = false
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadHtmlResults() })
            return error
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.apiId = this.$route.params.id
      this.getDeployments()
      // this.setupHealthcheck()
      this.getApiMetaData().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.apiMetaData.productName,
            exact: true,
            disabled: false,
            to: `/products/${this.apiMetaData.productId}`
          },
          {
            text: 'Api',
            disabled: true
          },
          {
            text: this.apiMetaData.displayName,
            exact: true,
            disabled: false,
            to: `/apis/${this.apiId}`
          }
        ])
      })

      if (this.$route.query.reportId) {
        this.reportId = this.$route.query.reportId
        this.displayDownloadModal = true
        this.downloadHtmlResultsAsync()
      }
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style>
.v-application code {
  background-color: rgb(51, 51, 51) !important;
  color: #fff !important;
}

.refreshing {
  opacity: 50%;
}

.vdatetime-input {
  width: 300px !important;
}

.theme--dark .selected {
  background-color: #757575 !important;
}

.selected {
  background-color:#D9D9D6 !important;
}

.verb-selection {
  width: 105px;
  text-align: center;
}

.revision-selection {
  width: 100px;
}

.revision-creation {
  width: 200px;
}

.action-button {
  width: 100px;
}

.version-button {
  margin-left: 10px;
}

.SplitPane {
  overflow-y: scroll !important;
}

.v-progress-circular {
  margin: 1rem;
}
</style>
