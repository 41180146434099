var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Pending API Subscriptions:")])])],1),_c('v-row',{attrs:{"id":"v-step-5"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.deployments.environments)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subscriptionHeaders,"items":_vm.activeSubscriptionRequests,"items-per-page":5},scopedSlots:_vm._u([{key:"item.environmentId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getEnvironmentName(item.environmentId)))])]}},{key:"item.productId",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{attrs:{"href":'/product-view/' + item.productId,"target":"_blank"}},[_vm._v(_vm._s(_vm.getProductName(item.productId)))])])]}},{key:"item.requestDateTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.requestDateTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 action-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveSubscriptionRequest(item.id)}}},[_vm._v("Approve")]),_c('v-btn',{staticClass:"action-button",attrs:{"color":"error"},on:{"click":function($event){return _vm.revokeSubscriptionRequestConfirmation(item.id)}}},[_vm._v("Deny")])]}}],null,false,3333503217)}):_vm._e()],1),(!_vm.activeSubscriptionRequests || !_vm.deployments.environments)?_c('v-col',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Subscribers:")])])],1),_c('v-row',{attrs:{"id":"v-step-6"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.deployments.environments)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.completedSubscriptionHeaders,"items":_vm.completedSubscriptionRequests,"items-per-page":15},scopedSlots:_vm._u([{key:"item.environmentId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getEnvironmentName(item.environmentId)))])]}},{key:"item.productId",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{attrs:{"href":'/product-view/' + item.productId,"target":"_blank"}},[_vm._v(_vm._s(_vm.getProductName(item.productId)))])])]}},{key:"item.completionTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.completionTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 action-button",attrs:{"color":"warning"},on:{"click":function($event){return _vm.revokeSubscriptionRequestConfirmation(item.id)}}},[_vm._v("Revoke")])]}}],null,false,1870127482)}):_vm._e()],1),(!_vm.activeSubscriptionRequests || !_vm.deployments.environments)?_c('v-col',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }