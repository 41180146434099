<template>
  <v-container id="v-step-7">
    <v-row>
      <v-col>
          <h2>Api Settings:</h2>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="api.displayName" label="Api Display Name" :disabled="isSubmitted"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            :disabled="isSubmitted"
            v-model="api.environmentsRequiringApproval"
            :items="deployments.environments"
            item-text="environment.displayName"
            item-value="environment.id"
            label="Environments where manual approval is required"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-combobox
            v-model="api.tags"
            hint="Type a tag and press enter. Maximum of 5."
            :rules="tagRules"
            label="Tags"
            multiple
            persistent-hint
            small-chips
          >
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-switch
            v-model="api.isSubscribable"
            class="ma-4"
            label="Subscribable"
            :disabled="isSubmitted"
            hint="If enabled, this API will be available to all HCA developers"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <div style="margin-left: 15px;">
          <v-btn @click="updateApiSettings" :disabled="isSubmitted" :loading="isSubmitted">
            Save
          </v-btn>
        </div>
        <div  style="margin-left: 15px;">
          <v-btn @click="displayDeleteDialog" color="error">Delete Api</v-btn>
        </div>
        <div v-if="apiMetaData.showTraceButton"  style="margin-left: 15px;">
          <v-btn color="info" :href="getTraceLink()" target="_blank">Trace Api</v-btn>
        </div>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'settings-component',
  props: {
    api: {
      required: true
    },
    apiMetaData: {
      required: true
    },
    deployments: {
      required: true
    },
    apiId: {
      required: true
    }
  },
  data: () => ({
    isSubmitted: false,
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this API & all revisions? This action can not be undone',
        confirmMethod: () => {
          this.deleteApi()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteApi: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/products/`)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteApi() })
          })
    },
    validate: function () {
      if (!this.$refs.form.validate()) {
        return false
      }
      return true
    },
    updateApiSettings: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      this.api.productId = this.productId
      return axios(
        `${process.env.VUE_APP_BASEURL}/api/${this.apiId}/settings`,
        {
          method: 'PUT',
          data: JSON.stringify(this.api),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$store.commit('triggerSnack', { snackMessage: 'The api settings have been saved successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiSettings() })
            return error
          })
    },
    getTraceLink: function () {
      return `${process.env.VUE_APP_BASEMANAGEMENTURL}/platform/hca/proxies/${this.apiMetaData.apiName}/trace/${this.api.revision}/`
    }
  }
}
</script>

<style scoped>

</style>
