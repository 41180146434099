<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Health Checks:</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="ma-2" @click="addNewTest" color="info">Add New Health Check</v-btn>
        <v-btn class="ma-2" @click="refreshTests" :loading="testLoading">Refresh Checks</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="testLoading">
      <v-col>
        <v-skeleton-loader
          ref="skeleton"
          type="table"
          class="mx-auto"
        ></v-skeleton-loader>
        </v-col>
      </v-row>
    <v-row>
      <v-col>
        <v-simple-table v-if="testConfigurations && !testLoading" :disabled="testLoading" v-bind:class="{ refreshing: testLoading }">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Enabled</th>
                <th class="text-left">Send Notifications</th>
                <th class="text-center">Snoozed</th>
                <th class="text-center">Postman<br/> Collection</th>
                <th class="text-center">Results</th>
                <th class="text-center">Last Result</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in testConfigurations" :key="item.id">
                <td>{{ item.name }}</td>
                <td><v-switch :disabled="testLoading" v-model="item.isEnabled" @change="toggleTestStatus(item, item.isEnabled, $event)"/></td>
                <td><v-switch :disabled="testLoading" v-model="item.isNotificationsEnabled" @change="toggleTestNotificationsStatus(item, item.isNotificationsEnabled, $event)"/></td>
                <td class="text-center">
                  <v-tooltip bottom v-if="item.snoozeNotificationsUntilDateTimeUtc && !currentSnoozeCheck(item.snoozeNotificationsUntilDateTimeUtc)">
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="sleepTestNotifications(item)"><v-icon color="blue">mdi-sleep</v-icon></v-btn>
                    </template>
                    <span>Snoozing until {{ getToDate(item.snoozeNotificationsUntilDateTimeUtc) }}. Click to edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" icon v-on="on" @click="sleepTestNotifications(item)"><v-icon>mdi-sleep-off</v-icon></v-btn>
                    </template>
                    <span>Snooze is currently off. Click to set</span>
                  </v-tooltip>
                </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="downloadTestCollection(item)"><v-icon>mdi-cloud-download</v-icon></v-btn>
                    </template>
                    <span>Download Postman Collection</span>
                  </v-tooltip>
                </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="displayTestResults(item)"><v-icon>mdi-view-list</v-icon></v-btn>
                    </template>
                    <span>Open health check results window</span>
                  </v-tooltip>
                  </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" @click="viewHtmlResults(item, item.testResults[0])" icon v-if="item.testResults && item.testResults.length > 0 && item.testResults[0].isTestSuccess"><v-icon color="green">mdi-shield-check</v-icon></v-btn>
                      <v-btn :disabled="testLoading" v-on="on" @click="viewHtmlResults(item, item.testResults[0])" icon v-if="item.testResults && item.testResults.length > 0 && !item.testResults[0].isTestSuccess" ><v-icon color="error">mdi-shield-alert</v-icon></v-btn>
                      <v-btn :disabled="testLoading"  v-on="on" icon v-if="!item.testResults || item.testResults.length == 0"><v-icon>mdi-shield-sync</v-icon></v-btn>
                    </template>
                    <span>View last health check result in new tab</span>
                  </v-tooltip>
                </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="editTestConfiguration(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Health Check</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="deleteTestConfigurationConfirmation(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Health Check</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="testLoading" v-on="on" icon @click="executeTest(item)">
                        <v-icon>mdi-shield-sync</v-icon>
                      </v-btn>
                    </template>
                    <span>Run Health Check Now</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayTestModal" width="800">
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Add Health Check
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="healthForm">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="currentTest.name"
                        label="Name"
                        :rules="requiredField"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-file-input
                        label="PostmanCollection"
                        v-model="currentFile"
                        :loading="isPostmanUploading"
                        :rules="requiredField"
                      ></v-file-input>
                    </v-col>
                    <div v-if="fileTypeError" class="v-messages__message error--text user-error">The requested file type is not supported. Please ensure your file is a .json postman v2.1 collection</div>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Enable Test" v-model="currentTest.isEnabled" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Enable Notifications" v-model="currentTest.isNotificationsEnabled" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Send Notifications to Product Team" v-model="currentTest.useProductTeamForNotifications" />
                    </v-col>
                  </v-row>
                  <v-row v-if="!currentTest.useProductTeamForNotifications">
                    <v-col cols="12" md="12" id="v-step-4">
                      <user-search-component class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentUserSearch" label="Notification Users" />
                      <div v-if="userError" class="v-messages__message error--text user-error">At least 1 user is required</div>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">34</th>
                              <th class="text-left">Name</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="user in currentNotificationUsers" :key="user.Key">
                              <td>{{ user.Key }}</td>
                              <td>{{ user.Value }}</td>
                              <v-btn icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" style="border-left: 1px solid #D9D9D6">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <h2>Instructions</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p>Health checks allow you to upload a postman collection with tests that will run every 15 minutes. Health Checks should not alter an API, utilize GET calls to check status endpoints instead. If a test fails, an email notification will be sent and the run frequency will increase to every 5 minutes until the health check succeeds.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p>Export a postman v2.1 collection with tests from postman using the ellipses on the collection folder in the side bar.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p>Enable tests to run tests. Enable notifications to receive email notifications. And specify if you'd like notifications to be sent to the product team or override and provide a list of notification users.</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="displayTestModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isPostmanUploading"
            color="primary"
            text
            @click="submitNewTest"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayEditTestModal" width="800">
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Edit Health Check
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="healthEditForm">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="currentTest.name"
                        label="Name"
                        :rules="requiredField"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Enable Test" v-model="currentTest.isEnabled" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Enable Notifications" v-model="currentTest.isNotificationsEnabled" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Send Notifications to Product Team" v-model="currentTest.useProductTeamForNotifications" />
                    </v-col>
                  </v-row>
                  <v-row v-if="!currentTest.useProductTeamForNotifications">
                    <v-col cols="12" md="12" id="v-step-4">
                      <user-search-component class="required" id="v-step-5" :disabled="isSubmitted"  v-model="currentUserSearch" label="Notification Users" />
                      <div v-if="userError" class="v-messages__message error--text user-error">At least 1 user is required</div>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">34</th>
                              <th class="text-left">Name</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="user in currentNotificationUsers" :key="user.Key">
                              <td>{{ user.Key }}</td>
                              <td>{{ user.Value }}</td>
                              <v-btn icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select clearable label="Snooze Time" v-model="snoozeTime" :items="snoozeTimeItems"></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      Snoozed Until:
                      <date-time
                        type="datetime"
                        v-model="customSnoozeTime"
                        input-class="timePicker"
                        value-zone="America/Chicago"
                        :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
                        :phrases="{ok: 'Continue', cancel: 'Exit'}"
                        :hour-step="1"
                        :minute-step="15"
                        :min-datetime="getCurrentTime()"
                        :week-start="7"
                        use12-hour
                        auto
                        ></date-time>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" style="border-left: 1px solid #D9D9D6">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <h2>Instructions</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p>Health checks allow you to upload a postman collection with tests that will run every 15 minutes. Health Checks should not alter an API, utilize GET calls to check status endpoints instead. If a test fails, an email notification will be sent and the run frequency will increase to every 5 minutes until the health check succeeds.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p>Enable tests to run tests. Enable notifications to receive email notifications. And specify if you'd like notifications to be sent to the product team or override and provide a list of notification users. Use snooze to keep health checks running but stop notifications for a set duration.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>Note: To change the postman collection, please delete the healthcheck and create a new one</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
          </v-form>
        </v-container>
      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="displayEditTestModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveEditedTest"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayTestResultsModal" width="500">
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          {{ currentTest.name }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-if="currentTest.testResults && currentTest.testResults.length > 0">
              <v-col>
                <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Completed</th>
                            <th class="text-center">Result</th>
                            <th class="text-center">View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in currentTest.testResults" :key="item.id">
                            <td>{{ getDate(item.completedDateTimeUtc) }}</td>
                            <td class="text-center">
                              <v-tooltip bottom v-if="item.isTestSuccess == false" >
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" color="error">mdi-shield-alert</v-icon>
                                </template>
                                <span>Health Check Failed</span>
                              </v-tooltip>
                              <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" color="green">mdi-shield-check</v-icon>
                                </template>
                                <span>Health Check Passed</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" @click="viewHtmlResults(currentTest, item)" icon>
                                    <v-icon>mdi-file-eye-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>View Health Check Report in New Tab</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" @click="downloadHtmlResults(currentTest, item)" icon>
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span>Download Health Check Report</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <p>No test results have been saved yet. Please check back here in about 15 minutes</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="displayTestResultsModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displaySnoozeTestNotificationsModal" width="500">
      <v-card>
        <v-card-title
          :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
          primary-title
        >
          Snooze {{ currentTest.name }} Notifications
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>If you're expecting downtime or need some time to resolve issues without sending alerts. You can use the selection below to set a snooze on notifications. Health Checks will still run and you can see the results on this page, but notifications will not be sent.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select clearable label="Snooze Time" v-model="snoozeTime" :items="snoozeTimeItems"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Snoozed Until:
                <date-time
                  type="datetime"
                  v-model="customSnoozeTime"
                  input-class="timePicker"
                  value-zone="America/Chicago"
                  :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  :hour-step="1"
                  :minute-step="15"
                  :min-datetime="getCurrentTime()"
                  :week-start="7"
                  use12-hour
                  auto
                  ></date-time>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="saveSnoozeTime"
          >
            Save
          </v-btn>
          <v-btn
            text
            @click="displaySnoozeTestNotificationsModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import userSearchComponent from '@/components/userSearchComponent'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'health-check-component',
  props: {
    apiId: {
      required: true
    }
  },
  components: {
    'user-search-component': userSearchComponent,
    'date-time': Datetime
  },
  watch: {
    currentFile: function (value) {
      if (value != null) {
        if (value.type !== 'application/json') {
          this.fileTypeError = true
          return false
        } else {
          this.fileTypeError = false
        }
        this.isPostmanUploading = true
        this.fileLoader.readAsText(value)
      }
    },
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.currentNotificationUsers.find(x => x.Key === newValue.Key)) {
          this.currentNotificationUsers.push(newValue)
        }
      }
    },
    displayEditTestModal: function (newValue, oldValue) {
      if (newValue === false) {
        this.stopAutoOpen = false
      }
    },
    displaySnoozeTestNotificationsModal: function (newValue, oldValue) {
      if (newValue === false) {
        this.stopAutoOpen = false
      }
    },
    snoozeTime: function (newValue, oldValue) {
      if (newValue === undefined) {
        this.customSnoozeTime = null
      } else if (newValue === 'Custom Time') {
        const elem = document.getElementsByClassName('timePicker')[0]
        if (elem) {
          elem.click()
        }
      } else if (newValue === '15 Minutes') {
        this.customSnoozeTime = moment(new Date()).add(15, 'm').format()
      } else if (newValue === '30 Minutes') {
        this.customSnoozeTime = moment(new Date()).add(30, 'm').format()
      } else if (newValue === '1 Hour') {
        this.customSnoozeTime = moment(new Date()).add(1, 'h').format()
      } else if (newValue === '1 Day') {
        this.customSnoozeTime = moment(new Date()).add(1, 'd').format()
      }
    }
  },
  data: () => ({
    fileTypeError: false,
    displayDownloadModal: false,
    snoozeTimeItems: ['15 Minutes', '30 Minutes', '1 Hour', '1 Day', 'Custom Time'],
    snoozeTime: null,
    isPostmanUploading: false,
    customSnoozeTime: null,
    displaySnoozeTestNotificationsModal: false,
    currentNotificationUsers: [ ],
    currentUserSearch: null,
    userError: false,
    fileLoader: new FileReader(),
    displayTestModal: false,
    displayTestResultsModal: false,
    displayEditTestModal: false,
    testLoading: false,
    currentTest: {
      postmanCollection: null,
      name: '',
      isEnabled: true,
      useProductTeamForNotifications: true,
      isNotificationsEnabled: true
    },
    currentFile: null,
    testConfigurations: [ ],
    requiredField: [
      v => !!v || 'Please Provide a value'
    ]
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getCurrentTime: function () {
      return moment().format()
    },
    sleepTestNotifications: function (test) {
      if (test.snoozeNotificationsUntilDateTimeUtc && !moment(new Date()).isAfter(test.snoozeNotificationsUntilDateTimeUtc)) {
        this.snoozeTime = 'Custom Time'
        this.customSnoozeTime = test.snoozeNotificationsUntilDateTimeUtc
      } else {
        this.snoozeTime = undefined
        this.customSnoozeTime = undefined
      }

      this.currentTest = test
      this.displaySnoozeTestNotificationsModal = true
    },
    saveSnoozeTime: function () {
      this.saveEditedTest(true)
      this.displaySnoozeTestNotificationsModal = false
      this.customSnoozeTime = undefined
      this.snoozeTime = undefined
      this.getAllTestConfigurations()
    },
    deleteUser: function (username) {
      this.currentNotificationUsers = this.currentNotificationUsers.filter((item) => {
        return item.Key !== username
      })
    },
    validateTestConfiguration: function () {
      this.validated = true
      this.userError = false
      if (this.currentNotificationUsers.length <= 0 && !this.currentTest.useProductTeamForNotifications) {
        this.userError = true
        this.validated = false
      }

      if (this.fileTypeError) {
        this.validated = false
      }

      return this.validated
    },
    editTestConfiguration: function (test) {
      if (test.snoozeNotificationsUntilDateTimeUtc && !moment(new Date()).isAfter(test.snoozeNotificationsUntilDateTimeUtc)) {
        this.snoozeTime = 'Custom Time'
        this.customSnoozeTime = test.snoozeNotificationsUntilDateTimeUtc
      } else {
        this.snoozeTime = undefined
        this.customSnoozeTime = undefined
      }

      this.currentTest = test

      if (this.currentTest.notificationUsers) {
        this.currentNotificationUsers = [ ]
        this.currentTest.notificationUsers.forEach(x => {
          this.getUserDetails(x).then(x => {
            if (x) {
              this.currentNotificationUsers.push(x[0])
            }
          })
        })
      } else {
        this.currentNotificationUsers = [ ]
      }

      this.displayEditTestModal = true
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    currentSnoozeCheck: function (time) {
      return moment(new Date()).isAfter(time)
    },
    saveEditedTest: function (skipValidate) {
      if (!skipValidate && !this.$refs.healthEditForm.validate()) {
        this.validated = false
      }

      if (this.currentNotificationUsers) {
        this.currentTest.notificationUsers = [ ]
        this.currentNotificationUsers.forEach(x => {
          this.currentTest.notificationUsers.push(x.Key)
        })
      }

      if (!this.validateTestConfiguration()) {
        return false
      }

      if (this.customSnoozeTime) {
        this.currentTest.snoozeNotificationsUntilDateTimeUtc = moment(this.customSnoozeTime).utc().format()
      } else {
        this.currentTest.snoozeNotificationsUntilDateTimeUtc = null
      }

      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/${this.currentTest.id}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.currentTest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.displayEditTestModal = false
            this.getAllTestConfigurations()
            this.$store.commit('triggerSnack', { snackMessage: 'The test has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.saveEditedTest() })
            return error
          })
    },
    deleteTestConfigurationConfirmation: function (test) {
      this.$store.commit('showDialog', {
        message: `Are you sure you wish to delete health check ${test.name}? This action cannot be undone`,
        confirmMethod: () => {
          this.deleteTestConfiguration(test)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteTestConfiguration: function (test) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/${test.id}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getAllTestConfigurations()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteTestConfiguration(test) })
          })
    },
    displayTestResults: function (test) {
      this.currentTest = test
      this.displayTestResultsModal = true
    },
    downloadTestCollection: function (test) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/collection/${test.id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob' // important
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${test.name}.postman-collection.json`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadTestCollection(test) })
            return error
          })
    },
    executeTest: function (test) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/${test.id}/run`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$store.commit('triggerSnack', { snackMessage: 'The check has been queued and will run momentarily. Please use the refresh button to update', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.executeTest(test) })
            return error
          })
    },
    viewHtmlResults: function (test, testResult) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/html/${testResult.id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var uri = 'data:text/html,' + encodeURIComponent(response.data)
            var newWindow = window.open(uri)
            newWindow.document.write(response.data)
            // var newWindow = window.open()
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.viewHtmlResults(test) })
            return error
          })
    },
    downloadHtmlResults: function (test, testResult) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/html/${testResult.id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob' // important
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${test.name}.result.${testResult.completedDateTimeUtc}.html`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadHtmlResults(test, testResult) })
            return error
          })
    },
    toggleTestStatus: function (test, newStatus, event) {
      test.isEnabled = newStatus
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/${test.id}`,
        {
          method: 'PUT',
          data: JSON.stringify(test),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$store.commit('triggerSnack', { snackMessage: 'The test has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.toggleTestStatus(test, newStatus, event) })
            return error
          })
    },
    toggleTestNotificationsStatus: function (test, newStatus, event) {
      test.isNotificationsEnabled = newStatus
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/${test.id}`,
        {
          method: 'PUT',
          data: JSON.stringify(test),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$store.commit('triggerSnack', { snackMessage: 'The test has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.toggleTestNotificationsStatus(test, newStatus, event) })
            return error
          })
    },
    refreshTests: function () {
      this.testConfigurations = [ ]
      this.getAllTestConfigurations()
    },
    addNewTest: function () {
      this.currentTest = {
        postmanCollection: null,
        name: '',
        isEnabled: true,
        useProductTeamForNotifications: true,
        isNotificationsEnabled: true
      }
      this.displayTestModal = true
    },
    submitNewTest: function () {
      if (!this.$refs.healthForm.validate()) {
        this.validated = false
        return false
      }

      if (!this.validateTestConfiguration()) {
        this.validated = false
        return false
      }

      if (this.currentNotificationUsers) {
        this.currentTest.notificationUsers = [ ]
        this.currentNotificationUsers.forEach(x => {
          this.currentTest.notificationUsers.push(x.Key)
        })
      }

      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${this.apiId}`,
        {
          method: 'POST',
          data: JSON.stringify(this.currentTest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.displayTestModal = false
            this.getAllTestConfigurations()
            this.$store.commit('triggerSnack', { snackMessage: 'The test has been saved successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.submitNewTest() })
            return error
          })
    },
    getAllTestConfigurations: function () {
      this.testLoading = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${this.apiId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.testLoading = false
            this.testConfigurations = response.data
          },
          (error) => {
            this.testLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAllTestConfigurations() })
          })
    },
    getToDate: function (date) {
      return moment(date).format('LLL')
    }
  },
  mounted: function () {
    this.fileLoader.addEventListener('load', (x) => {
      this.currentTest.postmanCollection = x.target.result
      this.isPostmanUploading = false
    })

    this.getAllTestConfigurations()
  }
}
</script>

<style scoped>

</style>
