<template>
  <v-container>
    <v-row v-if="chartData.length == 0">
      <v-col>
        <span>No results found, please check your date range</span>
      </v-col>
    </v-row>
    <v-row :class="chartData.length > 0 && chartData.length < 25 ? 'showChart' : 'hideChart'">
      <v-col>
        <time-line-multi-graph style="max-height: 400px; height: 400px;" label="Requests Per Subscriber" :chart-data="chartData" :time-unit="timeUnit"/>
      </v-col>
    </v-row>
    <v-row :class="chartData.length > 0 ? 'showChart' : 'hideChart'">
      <v-col cols="12" sm="12">
        <v-card :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'">
          <v-card-title>
            <span class="title font-weight-light">Totals</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  solo
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="totalRequests"
                  :items-per-page="10"
                  :search="search"
                  class="elevation-1">
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timeLineGraphMultiComponent from '@/components/timeLineGraphMultiComponent'

export default {
  name: 'MultiMetricComponent',
  props: ['chartData', 'options', 'timeUnit', 'label'],
  components: {
    'time-line-multi-graph': timeLineGraphMultiComponent
  },
  computed: {
    totalRequests: {
      get: function () {
        let records = [ ]
        this.chartData.forEach(record => {
          let total = 0
          if (record.metrics && record.metrics[0] && record.metrics[0].values) {
            record.metrics[0].values.forEach(item => {
              total += parseInt(item.value)
            })
          }
          records.push({
            name: record.name,
            total: total
          })
        })
        return records
      }
    },
    headers: {
      get: function () {
        return [
          { text: this.label, value: 'name' },
          { text: 'Total', value: 'total' }
        ]
      }
    }
  },
  data: () => ({
    search: ''
  }),
  methods: {
  },
  afterMount: function () {
  }
}
</script>

<style scoped>
  .showChart {
    display: block;
  }

  .hideChart {
    display: none;
  }
</style>
