<template>
  <v-container>
    <v-row>
        <v-col>
            <h2>Pending API Subscriptions:</h2>
        </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="subscriptionHeaders"
          :items="activeSubscriptionRequests"
          :items-per-page="5"
          class="elevation-1"
          v-if="deployments.environments"
        >
          <template v-slot:item.environmentId="{ item }">
            <span>{{ getEnvironmentName(item.environmentId) }}</span>
          </template>
          <template v-slot:item.productId="{ item }">
            <span><a :href="'/product-view/' + item.productId" target="_blank">{{ getProductName(item.productId) }}</a></span>
          </template>
          <template v-slot:item.requestDateTime="{ item }">
            <span>{{ getDate(item.requestDateTime) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" @click="approveSubscriptionRequest(item.id)" class="mr-2 action-button">Approve</v-btn>
            <v-btn color="error" @click="revokeSubscriptionRequestConfirmation(item.id)"  class="action-button">Deny</v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="!activeSubscriptionRequests || !deployments.environments">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Subscribers:</h2>
        </v-col>
    </v-row>
    <v-row id="v-step-6">
      <v-col cols="12">
        <v-data-table
          :headers="completedSubscriptionHeaders"
          :items="completedSubscriptionRequests"
          :items-per-page="15"
          class="elevation-1"
          v-if="deployments.environments"
        >
          <template v-slot:item.environmentId="{ item }">
            <span>{{ getEnvironmentName(item.environmentId) }}</span>
          </template>
          <template v-slot:item.productId="{ item }">
            <span><a :href="'/product-view/' + item.productId" target="_blank">{{ getProductName(item.productId) }}</a></span>
          </template>
          <template v-slot:item.completionTime="{ item }">
            <span>{{ getDate(item.completionTime) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="warning" @click="revokeSubscriptionRequestConfirmation(item.id)" class="mr-2 action-button">Revoke</v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="!activeSubscriptionRequests || !deployments.environments">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'subscription-component',
  props: {
    apiId: {
      required: true
    },
    deployments: {
      required: true
    }
  },
  computed: {
    completedSubscriptionRequests: {
      get: function () {
        if (this.subscriptionRequests) {
          return this.subscriptionRequests.filter(x => {
            return x.completionTime && x.approved
          })
        }
        return [ ]
      }
    },
    activeSubscriptionRequests: {
      get: function () {
        if (this.subscriptionRequests) {
          return this.subscriptionRequests.filter(x => {
            return x.completionTime === null
          })
        }
        return [ ]
      }
    }
  },
  data: () => ({
    subscriptionHeaders: [
      {
        text: 'Product',
        align: 'left',
        sortable: false,
        value: 'productId'
      },
      {
        text: 'Environment',
        align: 'left',
        sortable: false,
        value: 'environmentId'
      },
      {
        text: 'Requested Time',
        align: 'left',
        sortable: false,
        value: 'requestDateTime'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    completedSubscriptionHeaders: [
      {
        text: 'Product',
        align: 'left',
        sortable: false,
        value: 'productId'
      },
      {
        text: 'Environment',
        align: 'left',
        sortable: false,
        value: 'environmentId'
      },
      {
        text: 'Subscribed Since',
        align: 'left',
        sortable: false,
        value: 'completionTime'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    subscriptionRequests: [ ],
    products: [ ]
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getEnvironmentName: function (environmentId) {
      if (this.deployments.environments) {
        var environment = this.deployments.environments.filter(x => {
          return x.environment.id === environmentId
        })

        if (environment != null) {
          return environment[0].environment.displayName
        }
      }
      return 'Unknown'
    },
    getProductName: function (productId) {
      if (this.products) {
        var product = this.products.filter(x => {
          return x.id === productId
        })

        if (product && product[0]) {
          return product[0].displayName
        } else {
          return ''
        }
      }
      return ''
    },
    approveSubscriptionRequest: function (requestId) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${requestId}/approve`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getSubscriptionRequests()
            this.$store.commit('triggerSnack', { snackMessage: 'The subscription request has been approved', snackColor: 'success' })
          },
          (error) => {
            if (error) {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.approveSubscriptionRequest(requestId) })
            }
          })
    },
    revokeSubscriptionRequestConfirmation: function (requestId) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to revoke the subscription? This action cannot be undone',
        confirmMethod: () => {
          this.revokeSubscriptionRequest(requestId)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    revokeSubscriptionRequest: function (requestId) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${requestId}/revoke`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getSubscriptionRequests()
            this.$store.commit('triggerSnack', { snackMessage: 'The subscription request has been revoked', snackColor: 'success' })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.revokeSubscriptionRequest(requestId) })
          })
    },
    getSubscriptionRequests: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/apis/${this.apiId}?showCompleted=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.subscriptionRequests = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getSubscriptionRequests() })
          })
    },
    getAllProducts: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.products = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAllProducts() })
          })
    }
  },
  mounted: function () {
    this.getSubscriptionRequests()
    this.getAllProducts()
  }
}
</script>

<style scoped>

</style>
