<template>
  <v-container>
    <v-row v-if="chartData.length == 0">
      <v-col>
        <span>No results found, please check your date range</span>
      </v-col>
    </v-row>
    <v-row :class="chartData.length > 0 ? 'showChart' : 'hideChart'">
      <v-col>
        <time-line-graph style="max-height: 400px; height: 400px;" label="Requests" :chart-data="chartData" :time-unit="timeUnit"/>
      </v-col>
    </v-row>
    <v-row :class="chartData.length > 0 ? 'showChart' : 'hideChart'">
      <v-col cols="12" sm="2" class="text-center">
        <v-card :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'">
          <v-card-title>
            <span class="title font-weight-light">Total</span>
          </v-card-title>
          <v-card-text>
            <h1>{{ totalRequests }}</h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timeLineGraphComponent from '@/components/timeLineGraphComponent'

export default {
  name: 'single-metric-component',
  props: ['chartData', 'options', 'timeUnit', 'label'],
  components: {
    'time-line-graph': timeLineGraphComponent
  },
  computed: {
    totalRequests: {
      get: function () {
        let total = 0
        this.chartData.forEach(item => {
          total += parseInt(item.value)
        })
        return total.toLocaleString()
      }
    }
  },
  data: () => ({

  }),
  methods: {
  }
}
</script>

<style scoped>
  .showChart {
    display: block;
  }

  .hideChart {
    display: none;
  }
</style>
