<template>
  <v-card v-if="value.id" :flat="flat">
    <v-card-title>{{ value.displayName }}</v-card-title>
    <v-card-text>
      <v-container>
      <div v-if="value.id" class="policy-items">
        <h4>Description:</h4>
        <p v-if="value.description" style="margin-top: 10px"><span>{{ value.description }}</span></p>
        <p style="margin-top: 10px" v-else>No description for this policy</p>
        <h4>Settings:</h4>
        <div v-if="allowedProperties && allowedProperties.length > 0">
        <v-row v-for="(item, index) in allowedProperties" :key="index">
          <v-col cols="12">
            <v-text-field  v-if="item.policyPropertyType === 'String' || item.policyPropertyType === 0" v-model="item.value" :label="item.displayName" />
            <v-text-field  v-if="item.policyPropertyType === 'Number' || item.policyPropertyType === 1" type="number" v-model="item.value" :label="item.displayName" />
            <v-checkbox  v-if="item.policyPropertyType === 'Bool' || item.policyPropertyType === 2" v-model="item.value" :label="item.displayName" />
            <v-text-field @click:append="updatePasswordShow(item)" :type="item.showPassword ? 'text' : 'password'" :append-icon="item.showPassword ? 'mdi-eye' : 'mdi-eye-off'" v-if="item.policyPropertyType === 'Password' || item.policyPropertyType === 3" v-model="item.value" :label="item.displayName" />
          </v-col>
        </v-row>
        </div>
        <div v-else>
          <span>No settings to configure for this policy</span>
        </div>
      </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'policy-item-component',
  props: ['value', 'flat'],
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.model = newValue
        this.$emit('input', newValue)
      }
    },
    allowedProperties: {
      get: function () {
        if (this.model.properties) {
          return this.model.properties.filter(x => {
            x.showPassword = false
            return x.allowUserDefined
          })
        }
        return [ ]
      }
    }
  },
  data: () => ({

  }),
  methods: {
    updatePasswordShow: function (item) {
      item.showPassword = !item.showPassword
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
  .policy-items {
    margin-left: 15px;
    margin-top: -15px;
  }
</style>
